/**
 *
 * Icon widget
 *
 */
 Mmg.addShortcode(

    'icon',
    '.sc-icon',
  
    /**
     * @param {Element} el The shortcode's DOM element
     * @param {object} model The data model passed to the shortcode instance in data-json attribute
     * @param {jQuery} $ jQuery
     * @param {string} selector The shortcode selector that was passed to addShortcode
     * @param {string} className The name of the shortcode class
     */
    (el, model, $, selector, className) => {
  
    // Widget Declarations
    const $this = $(el);
    const $target = $(model.target);
  
    /**
     * Initialize the widget
     */
    (function init(){
        $this.on('click', handleClick);
    })();
  
  
    /**
     * Do action on click event.
     * 
     * @param {event} event 
     */
    function handleClick(event) {
        switch (model.action) {
            case 'modal': 
                showModal(event);
                break;
            case 'toggle': 
                toggle(event);
                break;
        }
    }


    /**
     * Show the target element in a modal.
     * @param {*} event 
     */
    function showModal(event) {
        event.preventDefault();
        Mmg.Modal.show(model.title || $('h1').text(), $target.html(), {});
    }


    /**
     * Toggle visibility of the target element.
     * @param {*} event 
     */
    function toggle(event) {
        event.preventDefault();
        if($target.is(':visible')) {
            $target.hide();
            $this.removeClass('active');
        } else {
            $target.show();
            $this.addClass('active');
        }
    }
  
  });
