Mmg.event = Mmg.event || {};

/**
 * Manage the stuck attribute of the sticky element.
 * !!! IMPORTANT: You need `position: sticky` and probably `top: -1`
 * !!! on the observable element for this feature to work as expected.
 * 
 * @param {string} selector 
 * @param {string} target If set, this selector will receive the 'stuck' attribute.
 */
Mmg.event.observeSticky = (selector, target) => {
    const callback = _.debounce(([e]) => {
        document.querySelector(target ?? selector).toggleAttribute('stuck', e.intersectionRatio < 1);
    }, 100, true);
    const observer = new IntersectionObserver( 
        callback,
        {threshold: [.999]}
    );
    
    observer.observe(document.querySelector(selector));
};
