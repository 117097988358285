jQuery( document ).ready(function( $ ) {

'use strict';

$('.sc-search').each(function(){

	var $this = $(this),
		$input = $this.find('input[type=text]'),
		$form = $this.find('form'),
		$submit = $form.find('.sc-search__button'),
		cache = {},
		templates = {
			pending: $this.find('.sc-search__template-pending').html(),
			suggestion: $this.find('.sc-search__template-suggestion').html(),
			notFound: $this.find('.sc-search__template-not-found').html()
		},
		model = $this.data('json'),
		endpoint = model.endpoint,
		topSuggestion
	;


	/**
	 * Initialize the widget
	 */
	(function init(){
		bind();
		$form.on('submit', onSubmit);
	})();


	/**
	 * Bind the input to the typeahead decorator
	 */
	function bind(){

		var bloodhoundSuggestions = new Bloodhound({
				datumTokenizer: Bloodhound.tokenizers.whitespace,
				queryTokenizer: Bloodhound.tokenizers.whitespace,
				remote: {
					url: endpoint + '?q=%QUERY',
					wildcard: '%QUERY',
					transform: function(response){
						cacheSet(response.results);
						return response.results;
					}
				}
			})
		;

		$input.typeahead(
			{
				minLength: 3,
				highlight: true,
				autoselect: true,
				hint: true
			},
			{
				name: 'search',
				displayKey: function (suggestion) {
					return stripTags(suggestion.post_title);
				},
				source: bloodhoundSuggestions,
				limit: 10,
				templates: {
					suggestion: function(input){
						return Mustache.render(templates.suggestion, input);
					},
					pending: function(input){
						return Mustache.render(templates.pending, input);
					},
					notFound: function(input){
						return Mustache.render(templates.notFound, input);
					}
				}
			}
		)
		.on('typeahead:render', function(event, suggestions) {

			// Store the top suggestion on every render because we may not
			// get more suggestions as users refine their search. This way
			// we can at least send them to a page on our site instead of
			// AAP's default search page.
			topSuggestion = suggestions[0];
		});


		$this.find('.tt-hint').on('change', function(){
			console.log('blur', this);
		});
	}


	/**
	 * [onSubmit description]
	 * @param  {[type]} event [description]
	 */
	function onSubmit(event){

		var searchTerm = $this.find('.tt-hint').val(),
			searchResult = cacheGet(searchTerm) || topSuggestion
		;

		$submit.prop('disabled', true);

		if(searchResult){
			event.preventDefault();
			window.location = searchResult.url;
		}
	}


	/**
	 * [setCache description]
	 * @param {[type]} items [description]
	 */
	function cacheSet(items){

		cache = _.chain(items)
			.indexBy(function(item){
				return item.post_title.toLowerCase();
			})
			.extend(cache)
			.value();
	}


	/**
	 * [isCached description]
	 * @param  {[type]}  item [description]
	 * @return {Boolean}      [description]
	 */
	function cacheGet(searchTerm){

		var key = searchTerm.toLowerCase();

		if(cache[key]){
			return cache[key];
		}
	}


	/**
	 * Strip HTML tags.
	 * This could be replaced with _.stripTags if we upgrade underscore.
	 * @param string html
	 * @return string
	 */
	function stripTags(html) {
		return $('<div/>').html(html).text();
	}
});
}); // EOF ready
