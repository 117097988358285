(function($){

    Mmg.Form = class {
    
      $el;
      storedValues = [];


      constructor($element, options) {
        this.$el = $element;
        this.options = options;
      }


      /**
       * Set the checked property on all checkboxes found in values.
       * @param {*} $group
       * @param {*} values
       */
      setCheckboxValues($group, values) {
        $group.each((index, el) => {
          const $input = $(el).find('input[type=checkbox]');
          if (values.includes($input.val())) {
            $input.prop('checked', true);
          } else {
            $input.prop('checked', false);
          }
        });
      }
  

      /**
       * Get all values from a checkbox group
       * @param {*} $el
       */
      checkboxValues($el) {
        return $.makeArray($el.map(function(){
          if($(this).is(':checked')) {
            return $(this).val();
          }
        }));
      }
  
  
      /**
       * Get all values from inputs
       * @param {*} $el
       */
      inputValues($el) {
        return $.makeArray($el.map(function(){
          return $(this).val();
        }));
      }
  
      /**
       * Get value from a select input
       * @param {*} $el
       */
      selectValue($el) {
        if ($el.data('value')) {
          return $el.data('value');
        }

        // String zero ('0') is a placeholder value.
        if ($el.val() === '0') {
          return;
        }
        return $el.val();
      }


      /**
       * Get the name attribute of a form group input.
       * @param {jQuery} $el
       */
      getInputName($el) {
        const $input = $el.parents('.form-group').find(':input[name]');
        const name = $input.attr('name') || '';
        return name.indexOf('[]') === -1 ? name : name.slice(0, -2);
      }


      /**
       * Get the title of a form-group element
       * @param {jQuery} $el
       */
      getInputTitle($el) {
        return $el.parents('.form-group-input').data('title');
      }
  
  
      /**
       *
       * @param {*} $input
       * @param {*} options
       */
      renderCheckboxGroup($input, options) {
        const name = this.getInputName($input);
        const title = this.getInputTitle($input);
        const selectedOptions = this.checkboxValues($input);
        const values = options[name] || [];
        const view = {
          name,
          title,
          options: values.map(value => ({
            value,
            attributes: `type="checkbox" class="form-check-input" name="${name}[]" value="${value}"`,
            checked: this.storedValues.includes(name) && selectedOptions.includes(value) ? 'checked' : '',
          })),
        };
        const template = this.$el.find('.form__template-checkbox-group').html();

        return Mustache.render(template, view);
      }

      /**
       *
       * @param {*} $input
       * @param {*} options
       */
      renderSelect($input, options) {
        const selectedOption = $input.val();
        const name = $input.attr('name');
        const title = $input.data('title');
        const id = $input.attr('id');
        const className = $input.attr('class');
        const attributes = `name="${name}" id="${id}" class="${className}"`;
        const values = options[name];
        const placeholder = $input.find("option[value='0']").text();
        const template = this.$el.find('.form__template-select').html();
        const view = {
          name,
          title,
          id,
          attributes,
          options: values.map(value => ({
            option: value,
            value,
            selected: this.storedValues.includes(name) && selectedOption === value ? 'selected' : '',
          })),
        };

        if (placeholder) {
          view.options.unshift({option: 0, value: placeholder, selected: false});
        }
        return Mustache.render(template, view);
      }


      /**
       *
       * @param {*} $input
       * @param {*} type
       * @param {*} options
       */
      renderNumberRange($input, options) {
        const $control = $input.parents('.form-number-range');
        const $min = $control.find('.form-number-range-min');
        const $max = $control.find('.form-number-range-max');
        const name = $control.data('name');
        const format = $control.data('format');
        const step = $control.data('step');
        const id = $control.attr('id');
        const title = this.getInputTitle($input);
        const values = options[name];
        const template = this.$el.find('.form__template-number-range').html();
        const view = {
          format,
          name,
          title,
          id,
          step,
          attributes_min: ` class="${$min.attr('class')}" id="${id}-min"`,
          attributes_max: ` class="${$max.attr('class')}" id="${id}-max"`,
        };
        let attributes = `type="number" name="${name}[]"`;

        if (this.storedValues.includes(name)) {
          attributes += ` min="${$min.attr('min')}" max="${$max.attr('max')}"`;
          view.attributes_min += ` ${attributes} value="${$min.val()}"`;
          view.attributes_max += ` ${attributes} value="${$max.val()}"`;
        } else {
          attributes += ` min="${values.min}" max="${values.max}"`;
          view.attributes_min += ` ${attributes} value="${values.min}"`;
          view.attributes_max += ` ${attributes} value="${values.max}"`;
        }

        return Mustache.render(template, view);
      }


      /**
       * Attach the slider behavior to range inputs
       */
      initSliders() {
        const getFormatted = this.getFormatted;
        const $form = this.$el.find('form');
        const $sliders = this.$el.find('.form-number-range');

        $sliders.each(function(index, el){
          const $el = $(el);
          const $min = $el.find('.form-number-range-min').hide();
          const $max = $el.find('.form-number-range-max').hide();
          const $minView = $('<div class="form__slider-min"></div>').insertAfter($min);
          const $maxView = $('<div class="form__slider-max"></div>').insertAfter($max);
          const name = $el.data('name');
          const format = $el.data('format');
          const step = Number($el.data('step')) || 1;
          const id = $el.attr('id');
          const min = Number($min.attr('min'));
          const max = Number($max.attr('max'));
          const range = {
            'min': min,
            'max': max
          };
          const slider = $(`<div id="${id}-slider" class="form__slider"></div>`).appendTo($el)[0];

          if (min === max) {
            range.min -= step;
            range.max += step;
          }
          const values = [

            // Floor the lower value to a step increment.
            Math.floor(($min.val() - range.min) / step) * step + range.min,

            // Ceiling the upper value to a step increment.
            Math.ceil(($max.val() - range.min) / step) * step + range.min
          ];

          noUiSlider.create(slider, {
            start: values,
            connect: true,
            step: step,
            range,
            format: {
              to: value => value,
              from:  value => value,
            },
          });

          if (min === max) {
            slider.setAttribute('disabled', true);
          }

          slider.noUiSlider.on('update', () => {
            const values = slider.noUiSlider.get();
            $minView.text(getFormatted(values[0], format));
            $maxView.text(getFormatted(values[1], format));
            $min.val(Math.round(values[0]));
            $max.val(Math.round(values[1]));
          });

          slider.noUiSlider.on('change', (values, handle) => {
            $form.trigger('change');
          });

        });
      }


      /**
       * Get a formatted value
       * @param {*} value
       * @param {*} formatter
       */
      getFormatted(value, formatter) {
        switch(formatter){
          case 'number':
            return accounting.formatNumber(value);
          case 'currency':
            return value === 0 ? '-' : accounting.formatMoney(value);
          case 'integer':
            return Math.round(value);
          default:
            return value;
        }
      }



      /**
       * Show invisible checkboxes in a modal
       * @param {*} event
       */
      showAllCheckboxes(event) {
        const $el = $(event.target);
        const $group = $el.parents('.form-group-input').find('.form-check');
        const $modalGroup = $group.clone().show();
        const $wrapper = $('<div class="form__modal-checkbox-group"></div>');
        const $form = this.$el.find('form');

        event.preventDefault();

        Mmg.Modal.show(
          this.getInputTitle($el),
          $wrapper.append($modalGroup),
          {},
          {
            className: 'modal-lg',
            buttonText: "Apply Filters",
            buttonAction: () => {
              this.setCheckboxValues(
                $group, 
                this.checkboxValues($modalGroup.find('.form-check-input'))
              );
              $form.trigger('change');
              // Catch known error (Bootstrap) "Error: Modal is transitioning"
              try {
                Mmg.Modal.hide();
              } catch (error) {
                // do nothing
              }
            },
          }
        );
      }

      /**
       * Hide checkboxes and show them in a dialog.
       */
      initCheckboxGroups() {
        const $groups = this.$el.find('.form-checkbox-group');
        const visibleCheckboxCount = this.options.visibleCheckboxCount ?? 10;
        $groups.each((index, el) => {
          const $group = $(el);
          const count = $group.find('.form-check').length;
          if (count > visibleCheckboxCount) {
            $group.find(`.form-check:gt(${visibleCheckboxCount - 1})`).hide();
            $group.append('<a class="form-checkbox-group-more" href="#see-more">See More</a>');
          }
        });
      }

    } // EOF class
    
})(jQuery);
