(function($){

	// Initialize the global shortcode storage object
	Mmg.shortcodes = {};

	let filters = [];
	let shortcodes = [];


	/**
	 * Add a shortcode to the register for initialization on load
	 * @param {string} shortcode The WordPress shortcode name
	 * @param {string} selector 
	 * @param {function} callback 
	 */
	Mmg.addShortcode = function(shortcode, selector, callback){
		addShortcode(shortcode, selector, callback, false);
	};
	

	/**
	 * Add a shortcode filter to the register for initialization before 
	 * shortcode.
	 * @param {string} shortcode The WordPress shortcode name
	 * @param {string} selector 
	 * @param {function} callback 
	 */
	Mmg.addShortcodeFilter = function(shortcode, selector, callback){
		addShortcode(shortcode, selector, callback, true);
	};


	/**
	 * Initialize all shortcodes on dom ready
	 */
	$(function(){
		filters.forEach(initializeShortcode);
		shortcodes.forEach(initializeShortcode);
	});


	/**
	 * Initialize a shortcode's js controller
	 * @param {string} shortcode The WordPress shortcode name
	 */
	function initializeShortcode(shortcode){
		$(shortcode.selector).each(function(){
			shortcode.callback(this);
		});
	}
	

	/**
	 * Regster shortcode controller with minimal boilerplate.
	 * @param {string}   shortcode The shortcode tag
	 * @param {string}   selector  The selector to decorate with the shortcode behavior
	 * @param {Function} callback  The shortcode controller
	 */
	function addShortcode(shortcode, selector, callback, isFilter){

		/**
		 * Curry the callback function with the merged data model and jQuery.
		 * 
		 * @param  {[type]} el The target element
		 * @param {object} arguments[1] Optional, extends the shortcode's data-json
		 */
		function callbackCurried(el){
	
			var modelArgs = arguments[1] || {},
				model = jQuery.extend(jQuery(el).data('json'), modelArgs),
				className = selector.replace(/^\./, '')
			;
	
			callback(el, model, jQuery, selector, className);
		}
	
		if(isFilter){
			filters.push({
				selector: selector, 
				callback: callbackCurried,
			});
		} 
		else {
			// Register the shortcode controller for programmatic access
			Mmg.shortcodes[shortcode] = callbackCurried;
			shortcodes.push({
				name: shortcode,
				selector: selector, 
				callback: callbackCurried,
			});
		}
	};

})(jQuery);
