/**
 *
 * Javascript behavior for: card_viewer shortcode
 *
 */

Mmg.addShortcode('card_viewer', '.sc-card-viewer', (el, model, $, selector, className) => {

	// widget vars
	var $this = $(el),
    $viewport = $this.find(`${selector}__viewport`),
		$slider = $this.find(`${selector}__cards`),
    $cards = $slider.find(`${selector}__card`),
		$next = $this.find(`${selector}__next`),
		$previous = $this.find(`${selector}__previous`),
		$pageIndicators = $this.find(`${selector}__pagination`),
    $sort = $this.find(`${selector}__sort`),

    cardTemplate = $this.find(`${selector}__card-template`).html(),
    paginationTemplate = $this.find(`${selector}__pagination-template`).html(),
    sortTemplate = $this.find(`${selector}__sort-template`).html(),
    cardSelector = `${selector}__card`,
    pageSelector = `${selector}__page`,
    sortSelector = `${selector}__sort-link`,
    loadingClass = 'is-loading',
    failClass = 'has-notice',
    layoutClasses = `${className}--grid ${className}--slider`,
    itemCount = $cards.length,
		pageCount,
    pageWidth,
		currentPage = 1,
		itemWidth,
		itemsPerPage,
		touchStartX,
		lastTouchX,
		sliderLeft,
		dragDirection,
		diffX,
		swipeXThreshold = 100,
    breakpoints = ['xs','sm','md','lg','xl'],
    breakpointColumns = getColumns(model.columns),
    breakpointRows = getRows(model.rows),
    cardHeight = $cards.height(),
    cardCollection,
    request,

    // Possible values are 'grid' or 'slider'
    layout = 'slider',
    sortField = model?.api_params?.sort,
    sortDirection = model?.api_params?.sort_direction
  ;

  const $filter = $this.find(`${selector}__filter`);
  const $filterResultCount = $this.find(`${selector}__filter-result-count`);
  const $totalFilterCount = $this.find(`${selector}__total-filter-count`);
  const $clearAllFilters = $this.find(`${selector}__clear-all-filters`);
  const filterForm = new Mmg.Form($this, {visibleCheckboxCount: Infinity});
  let filterValues = getFilters($filter);
  let $modalFilter = $('<div>');
  let visibleRowCount;

  // Store the css transition so we can disable it during layout changes
  // and re-enable after we're done positioning things
  const cssTransition = $slider.css('transition');

  /**
	 * Initialize the widget
	 */
	(function init(){
		filterForm.storedValues = Object.keys(filterValues);

		// Disable events for static card viewer
		if($this.hasClass('static')){
			return;
		}

    computeLayout();
    registerEvents();

    if(model.api_url){
      renderSort();
    }

    if(model.options_api_url){
      model.options_loaded = loadOptions();
      model.options_loaded.then(renderFilters);
    }

    if(model.api_url && (layout === 'grid' || model.ajax_on_load)){
      loadData(1);
    } else {
      adjustLayout();
    }

	})();


	/**
	 *	Register event handlers
	 */
	function registerEvents(){

		// Listen for breakpoint changes and adjust layout accordingly.
		$.breakpoint( 'change', onResize );

		// Register mouse events
		$this.on( 'click', `${selector}__next`, nextPage);
		$this.on( 'click', `${selector}__previous`, previousPage);
    $this.on( 'click', sortSelector, sort);

		$this.on( 'click', `${selector}__pagination a`, function( e ){
			e.preventDefault();
			showPage($(this).data('page'));
		});

    // TODO: Remove these handlers when sites have migrated from
    // `.truncate` to `intro_line_count`
    $this.on( 'click', `${selector}__intro-toggle--more`, handleShowMore);
    $this.on( 'click', `${selector}__intro-toggle--less`, handleShowLess);

    // Drag support to replace swipe events for sliders
    if(layout === 'slider') {
      $this.on('touchstart', '.row' , touchStart);
      $this.on('touchmove', '.row' , touchMove);
      $this.on('touchend', '.row' , touchEnd);
    }

    $this.on(
      'click',
      `${selector}__filter-button, ${selector}__filter-dropdown .close`,
      toggleDropdown
    );
    $filter.on('change', `.form-group-input`, handleFilterChange);
    $clearAllFilters.on('click', clearAllFilters);
    $this.on('click', `${selector}__filter-clear`, clearFilter);
    $this.on('click', `${selector}__show-filters`, showAllFilters);
    $(document).on('click', handleClickOutsideFilter);
	}


  /**
   * Clear all selected client side filter values.
   * @param {*} event
   */
  function clearAllFilters(event) {
    const $inputs = $filter.find('input[type=checkbox]');
    const $modalInputs =  $modalFilter.find('input[type=checkbox]');
    $inputs.prop('checked', false);
    $modalInputs.prop('checked', false);
    handleFilterChange();
  }


  /**
   * Show all filters in a modal.
   */
  function showAllFilters(){
    $modalFilter = $filter.clone();
    Mmg.Modal.show(
      'All Filters',
      $modalFilter,
      {hide: onHideModal},
      {buttonText: 'Clear Filters', buttonAction: clearAllFilters}
    );
  }


  /**
   * Copy values from modal view to filter form and update the counts.
   */
  function onHideModal(){
    const modalFilters = getFilters($modalFilter);
    model.filter.forEach((filter) => {
      const $group = $filter.find(`[data-filter=${filter.name}] .form-check`);
      filterForm.setCheckboxValues(
        $group,
        modalFilters[filter.name]
      );
    });
    handleFilterChange();
  }


  /**
   * Close filter dropdowns
   * @param {*} event
   */
  function handleClickOutsideFilter(event){
    if(!$(event.target).closest(`${selector}__filter`).length){
      $(`${selector}__filter .form-group`).removeClass('shown');
    }
  }


  /**
   * Clear all values for the filter.
   * @param {} event
   */
  function clearFilter(event){
    const $filter = $(event.target).closest(`${selector}__filter-dropdown`);
    $filter.find(`input[type=checkbox]`).prop('checked', false);
    $filter.find('.form-group-input').trigger('change');
  }


  /**
   * Toggle visiblity of a dropdown filter menu.
   * @param {*} event
   */
  function toggleDropdown(event){
    const $dropdowns = $(`${selector}__filter .form-group`);
    const $target = $(event.target).closest('.form-group');
    const shown = $target.hasClass('shown');
    $dropdowns.removeClass('shown');
    $target.toggleClass('shown', !shown);
  }


  /**
   * Load new dataset and update UI when filters change.
   */
  function handleFilterChange(event){
    const newFilterValues = getFilters($filter);
    filterForm.storedValues = Object.keys(newFilterValues);

    if (_.isEqual(newFilterValues, filterValues)) {
      return;
    }

    loadData(1);
    updateFilterCount();
    filterValues = newFilterValues;
  }


  /**
   * Update the count of chosen values for the filter.
   * @param {*} element
   */
  function updateFilterCount(){
    const filters = getFilters($filter);
    let totalFilterCount = 0;

    model.filter.forEach((filter) => {
      const $input = $filter.find(`input[name^=${filter.name}]`);
      const $formGroup = $input.closest('.form-group');
      const $count = $formGroup.find(`${selector}__filter-count`);
      totalFilterCount += filters[filter.name].length;
      $count.text(filters[filter.name].length || '');
      $totalFilterCount.text(totalFilterCount  || '');
      $formGroup.toggleClass('active', !!filters[filter.name].length);
    });

    $clearAllFilters.toggle(!!totalFilterCount);
  }


  /**
   * Handle resize events
   * @param  {[type]} breakpoint [description]
   * @return {[type]}            [description]
   */
  function onResize(breakpoint){
    currentPage = 1;
    computeLayout();

    if(model.api_url){
      loadData(currentPage);
    }
    else{
      adjustLayout();
    }
  }


  /**
   * Handle loaded data event
   * @return {[type]} [description]
   */
  function loadData(page){
    let hooks = $this.data('filters') || {};

    if(typeof hooks.beforeAjax === "function"){
      hooks.beforeAjax(model, () => {
        doLoad(page);
      });
    }
    else{
      doLoad(page);
    }
  }


  /**
   * Load data for one page of cards
   * @param {object} page
   */
  function doLoad(page){
    let params = _.clone(model.api_params);
    let max = model.api_params.max;
    const clientSideFilters = getFilters($filter);

    $viewport.addClass(loadingClass);
    currentPage = page;
    params.page = page;
    params.max = (layout === 'grid') ? itemsPerPage : max;

    if(sortField){
      params.sort = sortField;
      params.sort_direction = sortDirection;
    }

    // Check for server side filters
    params.filters = params.filters ?? {};

    // Check for client side filters
    Object.assign(params.filters, clientSideFilters);

    params.client_side_filters = Object.keys(clientSideFilters);

    // Set cache to false in some instances to get around Chrome's cache
    let cacheRequest = (!_.isUndefined(model.use_api_cache) && model.use_api_cache === false) ? false : true;

    // Abort any old requests so we don't render the incorrect state
    if(request && request.readyState != 4){
      request.abort();
    }

    request = $.ajax({
      dataType: "json",
      url: model.api_url,
      cache: cacheRequest,
      data: params,
    })
    .done(onLoad)
    .fail(onFail);
  }


  /**
   * Load filterable options
   * @returns Deferred
   */
  function loadOptions(){
    return $.ajax({
      dataType: "json",
      url: model.options_api_url,
      data: model.options_api_params,
    });
  }


  /**
   * Execute after failed data load attempt
   */
  function onFail(){
    $this.addClass(failClass);
    $viewport.removeClass(loadingClass);
  }


  /**
   * Handle loaded data
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  function onLoad(data){
    let formattedTotalCount;
    let formattedItemCount;

    itemCount = (layout === 'grid') ? data.count : data.items.length;
    totalItemCount = data.count;
    cardCollection = data.items;
    options = data.options;

    if(!itemCount){
      onFail();
    } else {
      $this.removeClass(failClass);
      $this.removeClass('rendered-at-server');
    }

    if(data.count_unfiltered){
      formattedTotalCount = accounting.formatNumber(data.count_unfiltered);
      formattedItemCount = accounting.formatNumber(itemCount);

      $filterResultCount.text(`${formattedItemCount} of ${formattedTotalCount}`);
    }

    render(cardCollection, options);
  }


	/**
	 * Get the x-position of the touch event
	 * @param  {Event} e
	 * @return {int}
	 */
	function getTouchX(e){
		return e.originalEvent.touches[0].pageX;
	}


	/**
	 * Begin tracking mouse position
	 * @param  {Event} e
	 */
	function touchStart(e){
		diffX = 0;
		touchStartX = getTouchX(e);
		sliderLeft = parseInt($slider.css('left'), 10) || 0;
		$slider.addClass('is_dragging');
	}


	/**
	 * Begin tracking changes in mouse position and set dragDirection
	 * @param  {Event} e
	 */
	function touchMove(e){

		const touchX = getTouchX(e);

		diffX = touchStartX - touchX;
		$slider.css('left', sliderLeft - diffX);

		if(lastTouchX > touchX){
			dragDirection = 'right';
		}
		else if (lastTouchX < touchX) {
			dragDirection = 'left';
		}

		lastTouchX = touchX;
	}


	/**
	 * Fire the navigation callbacks based on drag direction
	 * when touch event completes.
	 * @param  {[type]} e [description]
	 * @return {[type]}   [description]
	 */
	function touchEnd(e){

		const callbacks = {
			right: nextPage,
			left: previousPage
		};

		if(Math.abs(diffX) < swipeXThreshold){
			showPage( currentPage);
		} else {
      callbacks[dragDirection] && callbacks[dragDirection](e);
      dragDirection = '';
      $slider.removeClass('is_dragging');
    }


	}


	/**
	 *	Display the next page
	 */
	function nextPage( e ){

		e.preventDefault();

		if( pageCount > currentPage ){
			currentPage++;
		}

		showPage( currentPage );
	}


	/**
	 *	Display the previous page
	 */
	function previousPage( e ){

		e.preventDefault();

		if( currentPage > 1 ){
			currentPage--;
		}

		showPage( currentPage );
	}


  /**
   * Show the page by number
   * @param  {[type]} number [description]
   * @return {[type]}        [description]
   */
  function showPage(number){

    if(model.api_url && layout === 'grid'){
        loadData(number);
        $('html, body').animate({scrollTop: $this.offset().top}, 1000);
    }
    else{
      $slider.css( 'left', - ( ( number - 1 ) * pageWidth ) );
    }
    currentPage = number;
    updateNavigation();
  }


  /**
   * @return {[type]} [description]
   */
  function computeLayout(){

    const bp = $.breakpoint('get');
    const columnUnits = breakpointColumns[bp.size];
    const rowCount = breakpointRows[bp.size];
    const containerWidth = $viewport.width();
    const columnCount = 12 / columnUnits;
    let visibleItemCount;

    // Widget scope
    pageWidth = bp.containerWidth;
    itemsPerPage = rowCount * columnCount;
    itemWidth = containerWidth / columnCount;
    pageCount = Math.ceil(itemCount / itemsPerPage);
    layout = (rowCount > 1) ? 'grid' : 'slider';

    // The number of rows on the final page varies by how many remaining items
    // there are to show. Compute the number of visible rows.
    if(pageCount === currentPage){
      visibleItemCount = itemsPerPage - ((pageCount * itemsPerPage) - itemCount);
      visibleRowCount = Math.ceil(visibleItemCount / columnCount);
    }
    else{
      visibleRowCount = rowCount;
    }

    // Add layout class
    $this.removeClass(layoutClasses);
    $this.addClass(`${className}--${layout}`);
  }


  /**
   * Get the height of the viewport based on rowCount and card layout
   * @param  {[type]} rowCount [description]
   * @return {[type]}          [description]
   */
  function getViewportHeight(rowCount){
    const paddingTop = parseInt($cards.css('padding-top'), 10);
    const paddingBottom = parseInt($cards.css('padding-bottom'), 10);
    const rowSpacing =  paddingTop + paddingBottom;
    cardHeight = $cards.height();
    return (rowSpacing + cardHeight) * rowCount - rowSpacing;
  }


	/**
	 *	Modify the item width based on breakpoint.
	 *
	 *	@param object breakpoint An object containing details about the current breakpoint.
	 */
	function adjustLayout(){

    let leftPosition = 0;

    // Center the items if there is not
    // at least one full page of items.
    if(itemCount < itemsPerPage && layout === 'slider'){
      leftPosition = ( ( itemsPerPage - itemCount ) * itemWidth / 2 );
    }

    // Split cards from DOM into groups of pages
    if(!model.api_url){
      renderDomPages();
    }

    $cards = $slider.find(cardSelector);
    $pages = $slider.find(pageSelector);

    $slider.css('transition', 'none');
    $cards.innerWidth(itemWidth);
    $viewport.height(getViewportHeight(visibleRowCount));
    $pages.width(pageWidth);
    $slider.width(pageWidth * pageCount).css('left', leftPosition);
    $viewport.removeClass(loadingClass);

		updateNavigation();

    // Re-enable the css transition in the next event loop
    _.defer(() => {
      $slider.css('transition', cssTransition);
    });
	}


	/**
	 *	Show/hide navigtion elements
	 */
	function updateNavigation(){
		pageCount > currentPage ? $next.show() : $next.hide();
		currentPage > 1 ? $previous.show() : $previous.hide();

    switch(layout){
      case 'grid':
        renderPagination();
        break;
      case 'slider':
        renderPageIndicators();
        break;
    }
	}


	/**
	 *	Show the page indicators and highlight current page
	 */
	function renderPageIndicators( ){

		var pages = [],
			$page
		;

		for( var i = 0; i < pageCount; i++){
			$page = $( `<a href="#" class="${className}__page-indicator" data-page="${i+1}" aria-label="Page ${i+1}">` );

			if( i === currentPage - 1 ){
				$page.addClass( 'is_active' );
			}

			pages.push( $page );
		}
		$pageIndicators.html( pages );

		if( itemCount <= itemsPerPage ){
			$pageIndicators.hide();
		}
		else{
			$pageIndicators.show();
		}

    // Trigger event once render is complete
    $this.trigger('pagination.render', [currentPage, pageCount]);
	}


  /**
   * Parse input format (items per column, per breakpoint)
   * into unit based format (number of columns per item, per breakpoint)
   * 12/input = output
   * @param  {array} columns The list of input columns
   * @return {object}        The list of column counts keyed by breakpoint size
   */
  function parseInputColumns(columns){
    return _.chain(columns).map(input => {
      const column = input.split('-');
      column[1] = 12/column[1];
      return column;
    }).object().value();
  }


  /**
   * Parse input format (items per column, per breakpoint)
   * into unit based format (number of rows per item, per breakpoint)
   * 12/input = output
   * @param  {array} rows The list of input rows
   * @return {object}        The list of row counts keyed by breakpoint size
   */
  function parseInputRows(rows){
    return _.chain(rows).map(input => {
      return input.split('-');
    }).object().value();
  }


  /**
   * Get the number of columns to render for each breakpoint
   * @param {object} defaults Default column map to use
   * @return {object} Map of columns per item by breakpoint
   */
  function getColumns(input){
    const inputs = parseInputColumns(input);
    return getGridBreakpoints(inputs, 12);
  }


  /**
   * Get the number of rows to render for each breakpoint
   * @param {object} defaults Default column map to use
   * @return {object} Map of columns per item by breakpoint
   */
  function getRows(input){
    const inputs = parseInputRows(input);
    return getGridBreakpoints(inputs, 1);
  }


  /**
   * Step through all the breakpoints, setting values from input. If a
   * breakpoint is missing a value, use the value from the previous step.
   */
  function getGridBreakpoints(inputs, initialValue){

    let previousValue = initialValue;

    return _.chain(breakpoints).map(breakpoint => {
      previousValue = inputs[breakpoint] || previousValue;
      return [breakpoint, previousValue];
    }).object().value();
  }


  /**
   * Render all the cards
   * @return {[type]} [description]
   */
  function render(data, options){
    let cards;
    let hooks = $this.data('filters') || {};

    if(typeof hooks.beforeRender === "function"){
      data = hooks.beforeRender(data);
    }

    computeLayout();

    cards = _.map(data, (item) => {
      let card = Mustache.render(
        cardTemplate,
        _.extend(item, { label: model.labels })
      );

      if (item.hasOwnProperty("schema")) {
        card = card + `<script type="application/ld+json">${item.schema}</script>`;
      }

      return card;
    });

    renderPages(cards);
    adjustLayout();

    // Trigger event once render is complete
    $this.trigger('render',[model, data, options]);
  }


  /**
   * [sort description]
   * @param  {[type]} data      [description]
   * @param  {[type]} field     [description]
   * @param  {String} direction [description]
   * @return {[type]}           [description]
   */
  function sort(event){
    event.preventDefault();
    sortDirection = (sortDirection === 'asc') ? 'desc' : 'asc';
    sortField = $(this).data('sort');
    loadData(1);
    renderSort();
  }


  /**
   * Break an array into smaller arrays
   * @param  {array} data
   * @param  {integer} chunkSize The number of items in each chunk
   * @return {array}             Array of smaller arrays
   */
  function arrayChunk(data, chunkSize){
    return _.chain(data).groupBy((element, index) => {
      return Math.floor(index/chunkSize);
    }).toArray().value();
  }


  /**
   * Render the numbered page navigation for ajax pagination
   * @return {[type]} [description]
   */
  function renderPagination(){
    const maxLinks = 5;
    const offset = (maxLinks - 1) / 2;
    const rangeStart = Math.min(
      Math.max(1, currentPage - offset),
      Math.max(1, (pageCount + 1) - maxLinks)
    );
    const rangeEnd = Math.min(rangeStart + maxLinks, pageCount + 1);
    const range = _.range(rangeStart, rangeEnd);
    const previous = currentPage - 1;
    const next = (currentPage < pageCount) ? (currentPage + 1) : '';

    if(range[0] !== 1){
      range[0] = 1;
    }

    if((rangeEnd - 1) !== pageCount){
      range[range.length - 1] = pageCount;
    }

    const pages = _.map(range, index => {
      return {
        page: index,
        selected: index === currentPage,
        ellipsis_before: (index === pageCount && rangeEnd - 1 !== pageCount),
        ellipsis_after: (index === 1 && range[1] !== 2),
      };
    });

    if(pageCount < 2){
      $pageIndicators.empty();
      return;
    }

    $pageIndicators.html(Mustache.render(paginationTemplate, {
      pages: pages,
      previous: previous,
      next: next,
      last: pageCount,
    }));
  }


  /**
   * Render the sorting links
   * @return {[type]} [description]
   */
  function renderSort(){
    const sortFields = _.map(model.sort, field => {
      const selected = sortField === field.name;
      return {
        name: field.name,
        label: field.label,
        selected: selected,
        asc: selected && sortDirection === 'asc',
        desc: selected && sortDirection === 'desc',
      }
    });

    $sort.html(Mustache.render(sortTemplate, {
      fields: sortFields,
      is_sorted: !!sortField,
    }));
  }


  /**
   * Re-render the cards in DOM in groups of pages
   * @return {[type]} [description]
   */
  function renderDomPages(){
    const cards = $cards.map((index, card) => card.outerHTML);
    renderPages(cards);
  }


  /**
   * Render collection of cards, grouped by page
   * @param  {[type]} cards [description]
   * @return {[type]}       [description]
   */
  function renderPages(cards){
    const pages = arrayChunk(cards, itemsPerPage).map((items) => {
      const $page = $(`<div class="${className}__page"></div>`);
      return $page.html(items)
    });

    $slider.html(pages);
  }


  /**
   * Show expanded intro
   * @param {*} event
   */
  function handleShowMore(event) {
    event.preventDefault();
    $this.addClass('show');
  }


  /**
   * Show collapsed or truncated intro
   * @param {*} event
   */
  function handleShowLess(event) {
    event.preventDefault();
    $this.removeClass('show');
  }


  /**
   * Render filter form
   * @param {object} data
   */
  function renderFilters(data) {
    const filters = [];

    model.filter.forEach((filter) => {
      const $input = $filter.find(`input[name^=${filter.name}]`);
      filters.push(filterForm.renderCheckboxGroup($input, data.options));
    });

    $filter.html(filters.join('')).show();
  }


  /**
   * Get values for all filters
   * @returns object
   */
  function getFilters($form) {

    const filters = {};

    model.filter.forEach((filter) => {
      const $input = $form.find(`input[name^=${filter.name}]`);
      filters[filter.name] = filterForm.checkboxValues($input);
    });

    return filters;
  }

});
