/**
 * Javascript behavior for Map Offices
 *
 */
Mmg.addShortcodeFilter(
  "map--offices",
  ".sc-map--offices",
  (el, model, $, selector, className) => {

    // Widget variables
    const $this = $(el);
    let parentSelector = '.sc-map';
    let markerSelector = '.map-icon-label';
    let highlightMarkerClass = "map-icon-label--highlight";
    let highlightedMarkerId;
    
    let anchorMmgOptions = Mmg['google-map-anchor-options'] || [];

    
    /**
     * Initialize the widget filter
     */
    (function init() {
      $this.data('filters', {
        processMarkers: processMarkers
      });
    })();

    
    /**
     * Process markers for the Map Offices
     * @param {array} type
     * @param {object} location
     * @param {google.maps.Map} map
     * @param {object} bounds
     */
    function processMarkers(type, locations, map, bounds) {
        
      const anchorOptions = {
        url: 'https://maps.gstatic.com/mapfiles/transparent.png',
        size: new google.maps.Size(5, 5),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 31)
      };
      
      const markerIcon = $.extend({}, anchorOptions, anchorMmgOptions);

      const markers = $.map(locations, location => {
        const latLng = new google.maps.LatLng(
          location.latitude,
          location.longitude
        );
        
        // Calculate z-index using latitude so markers don't overlap
        // doing a ceil in a latitude like 18.322785 and 18.322384 would result in the same number
        // So the value is multiplied and then divided to generate a sorter number
        // Using negative value so the highest markers lays behind each other
        // and also cluster markers
        let zIndex = -Math.ceil(location.latitude * 1000);
        
        const marker = new Marker({
          map: map,
          position: latLng,
          icon: markerIcon,
          map_icon_label: `<span class="sc-map__marker" style="z-index:${zIndex};" data-id="${location.id}"></span>`,
        });

        addMarkerEvents(marker, location);

        bounds.extend(latLng);

        return marker;
      });

      highlightedMarkerId = locations[0].id;
      renderAddressBlockInfo(locations[0]); 

      // Events that are triggered when all the tiles on the map have been loaded
      map.addListener('tilesloaded', function(){

        resetMarkersHighlight();

        // Highlights the marker that corresponds to the current 'highlightedMarkerId'
        let $highlightedMarker = $(document).find(`[data-id=${highlightedMarkerId}]`).parent();
        $highlightedMarker.addClass(highlightMarkerClass);

      });

      // Center map and adjust Zoom based on the position of all markers.
      setTimeout(() => {
        if (model.zoom_level) {
            map.setZoom(model.zoom_level);
        } else {
            map.fitBounds(bounds);
        }
      });

      return markers;
    }

    
    /**
     * Add events on markers
     * @param {object} marker The marker instance
     * @param {object} location The location associated with the marker
     */
    function addMarkerEvents(marker, location) {
      marker.addListener('click', function (){

        highlightMarker(location);

        renderAddressBlockInfo(location);
      });
    }


    /**
     * Highlight marker clicked and set its 'location.id' to state variable 'highlightedMarkerId'.
     * 
     * @param {object} location The location associated with the marker
     */
    function highlightMarker(location){

      resetMarkersHighlight();

      highlightedMarkerId = location.id;

      // Find html node of clicked marker and add highlight class
      let $clickedMarker = $(document).find(`[data-id='${location.id}']`).parent();
      $clickedMarker.addClass(highlightMarkerClass);
    }
    
    
    /**
     * Render all information of selected location in 'address block'.
     * 
     * @param {object} location The location associated with the marker
     */
    function renderAddressBlockInfo(location) {

      let addressGeoInfo = `${location.street_address}, ${location.city}, ${location.state} ${location.zipcode}`;

      let $addressBlockText = $this.find(`${parentSelector}__address`);
      let $addressBlockPhone = $this.find(`${parentSelector}__link`);
      
      $addressBlockText.text(addressGeoInfo);
      $addressBlockPhone.text(location.phone);
      $addressBlockPhone.attr('href', `tel:${location.phone}`); 
    }


    /**
     * Find all markers and clear highlight class from them.
     */
    function resetMarkersHighlight() {

      let $allMarkers = $(document).find(markerSelector);
      $allMarkers.removeClass(highlightMarkerClass);
    }
});
