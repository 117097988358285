/**
*	Breakpoint emitter for TWBS
*
*	MUST HAVE
*	- emit events when breakpoint changes
*	- emit TWBS semantic breakpoint sizes 
*
*	NICE TO HAVE
*	- Auto detect breakpoint sizes based on TWBS grid
*	-- cache the detected breakpoints
*
*	EMITS
*	=====
*	Event: change.breakpoint 
*	Data:
*	{ 
*		size ( xs | sm | md | lg | xl )
*		containerWidth
*		columnWidth
*	}
*	
*/

/* USAGE

// events
$.breakpoint( 'change', handler );

// getter
$.breakpoint( 'get' ); //returns: {size, columns, containerWidth, columnWidth}

*/
(function($) {

/*global _ */
'use strict';

$.breakpoint = function( ){

	var $w = $( window ),
		$container = $('.container'),
		columns = 12,
		breakpoints = $('body').data('breakpoints') || {
			xs: 0,
			sm: 576,
			md: 768,
			lg: 992,
			xl: 1200
		},
		size = getSize(),
		properties = getProperties(),
		callbacks = []
	;

	//	Emit breakpoints when width of the viewport changes.
	$w.resize( _.debounce( onResize, 300 ) );


	/**
	*	Get the size of the current breakpoint
	*/
	function getSize(){

		var breakpoint, key;

		for( key in breakpoints ){

			if( $w.width() >= breakpoints[key] ){
				breakpoint = key;
			}
			else{
				break;
			}
		}

		return breakpoint;
	}


	/**
	*	Get the properties of the current breakpoint
	*/
	function getProperties(){

		return {
			size: size,
			columns: columns,
			containerWidth: $container.filter(':visible').outerWidth(),
			columnWidth: $container.filter(':visible').outerWidth() / columns
		};
	}


	/**
	*	Emit the breakpoint size, container width and column width
	*/
	function onResize(){

		var i, 
			newsize = getSize(),
			newProperties = getProperties()
		;
		
		// Only emit when column width changes
		if(_.isEqual(newProperties, properties)){
			return false;
		}

		size = newsize;
		properties = getProperties();

		// Trigger callback stack
		for( i in callbacks ){
			callbacks[i]( properties );
		}

	}


	// API
	// =========================
	if( arguments[0] === 'get' ){
		return getProperties();
	}

	if( arguments[0] === 'change'  && $.isFunction( arguments[1] ) ){
		callbacks.push( arguments[1] );
	}


}; // EOF $.breakpoint

})(jQuery);
