/**
 * JavaScript controller for shortcode: fitment_form
 */
Mmg.addShortcode('style_guide', '.sc-style-guide', (el, model, $) => {

	const $this = $(el);
	const $iconFrame = $this.find('.sc-style-guide__icon-frame');
	const $popovers = $this.find('[data-toggle="popover"]');
	const $modalTrigger = $this.find('.sc-style-guide__show-modal');

	/**
	 * Initialize the widget
	 */
	(function init(){
		addEvents();
	})();


	/**
	 * Events
	 */
	function addEvents(){
		$popovers.popover();

		$modalTrigger.click(showModal);

		$iconFrame.on('load', resizeIconFrame);
		$(window).resize(resizeIconFrame);
	}


	/**
	 * Show Modal
	 */
	function showModal(){
		Mmg.Modal.show(
			"I'm Modal", 
			"Here is my content sir.",
			{
				'hide': () => {
					console.log('Modal Hidden');
				}
			},
			{
				buttonText: "Save",
				buttonAction: $.noop,
			}
		);
	}

	/**
	 * Resize icon demo iframe
	 */
	function resizeIconFrame(){
		$iconFrame.height($iconFrame.contents().height());
	}

});
