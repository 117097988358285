/**
 *	Collapsible
 *	A component for expanding/collapsing large record sets in widgets
 *	using semantic markup.
 */
jQuery(document).ready(function($){

$(".collapsible").each(function(){

	const $this = $(this);
	const $collapse = $this.find('.collapsible__collapse');
	const $links = $this.find('.collapsible__toggle');

	const lessSelector = '.collapsible__a--less';
	const moreSelector = '.collapsible__a--more';
	const min = $this.data('min');
	const max = $this.data('max') || Infinity;
	const pristineClasses = [];
	const SHOW_MAX_CLASS = 'show-max';

	let $records;

	/**
	 *	Initialize
	 */
	(function init(){

		// Register click events
		$this.on('click', lessSelector, showMin);
		$this.on('click', moreSelector, showMax);
		$this.on('render', render);

		// "accordion" style use <header> to toggle
		if (min === 0 ) {
			$this.on('click', 'header', handleHeaderClick);
		}

		render();
	})();


	/**
	 *
	 * @returns Render the collapsed state
	 */
	function render() {

		$links.hide();

		$records = $collapse.is('tbody') ? $collapse.children('tr') : $collapse.children('div');

		// Get array of pristine classes for each record
		$records.each(function(index){
			pristineClasses[index] = $(this).attr('class');
		});

		// show the widget
		$collapse.show();

		// If there are not enough records to toggle, do nothing else
		if($records.length <= min || max <= min){
			return false;
		}

		$links.show();

		// Display the collapsed state by default.
		if ($this.hasClass(SHOW_MAX_CLASS)) {
			showMax();
		} else {
			showMin()
		}
	}


	/**
	 * Trigger $showMin/$showMax click when header is clicked
	 * @param {*} event
	 */
	function handleHeaderClick(event) {
		$links.find(`${lessSelector}, ${moreSelector}`).filter(':visible').trigger('click');
	}


	/**
	 *	Show the minimum number of records
	 */
	function showMin(event){

		// If being called as a click handler
		// do not scroll to top.
		if(event){
			event.preventDefault();
			event.stopPropagation();
		}

		// Toggle link visibility
		$links.find(moreSelector).show();
		$links.find(lessSelector).hide();

		// Reapply pristine classes to each item
		$records.each(function(index) {
			$(this).attr('class', pristineClasses[index]);
		});

		// Hide rows greater than min
		$records.show().slice(min).hide();

		$this.removeClass(SHOW_MAX_CLASS);
	}


	/**
	 *	Show the maximum number of records
	 */
	function showMax(event){

		// If being called as a click handler
		// do not scroll to top.
		if(event){
			event.preventDefault();
			event.stopPropagation();
		}

		// When toggling all, remove any hidden classes
		$records.removeClass(function (index, className) {
			return (className.match(/(^|\s)hidden-\S+/g) || []).join(' ');
		});

		// Toggle link visibility
		$links.find(moreSelector).hide();
		$links.find(lessSelector).show();

		// Show all rows up to max
		$records.show().slice(max).hide();

		$this.addClass(SHOW_MAX_CLASS);
	}

});
});
