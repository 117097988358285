/**
 * LPC Instant Quote v1.0
 * Copyright 2022 Site Assemble
 */
jQuery(document).ready(function($){

  var widthFull           = Math.ceil( $(".step-one .elementor-container").width() ), // width of 'ONLINE QUOTE' container
      widthH2             = Math.ceil( $(".step-one h2").width() ),                   // width of H2 block
      widthIcon           = Math.ceil( $(".step-one .icon").width() ),                // width of the arrow icon separating H2 from zip input
      widthDefaultText    = Math.ceil( $(".pre-text").width() ),                      // remaining space left for text
      widthText           = Math.floor( widthFull - widthH2 - widthIcon ),            // remaining space left for text
      formBlock = "",
      formBlockDefault = "THANK YOU. Our Customer Care Staff will soon get in touch with you via your chosen contact method. We cannot wait to wow you with our customer service. And we look forward to providing you with further details and convenient scheduling options. Thank you for the opportunity to serve you.",
      formBlockError = "Unfortunately there has been an error with this form. Please contact us at 800-223-2847 for service."
      ;


  // generate random file name, insert into hidden form field
  function generateRandomFileName() {
      var text = "";
      var unixTime    = Math.floor(Date.now() / 1000);
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 16; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      $("input[name=file-name]").val(unixTime + "_" + text);
  }
  generateRandomFileName();


  // insert page into http-referrer hidden form field
  function populateReferrer() {
      var curPage = window.location.href;
      if (typeof curPage !== 'undefined') {
          $("input[name=http-referrer]").val(curPage);
      } else {
          $("input[name=http-referrer]").val("unknown");
      }
  }
  populateReferrer();


  // phone number formatter //

  function isNumericInput(event) {
      var key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
          (key >= 96 && key <= 105) // Allow number pad
      );
  }

  function isModifierKey(event) {
      var key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
          (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
          (key > 36 && key < 41) || // Allow left, up, right, down
          (
              // Allow Ctrl/Command + A,C,V,X,Z
              (event.ctrlKey === true || event.metaKey === true) &&
              (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
          )
  }

  function enforceFormat(event) {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
          event.preventDefault();
      }
  }

  function formatToPhone(event) {
      if(isModifierKey(event)) {return;}

      // I am lazy and dont like to type things more than once
      var target = event.target;
      var input = event.target.value.replace(/\D/g,"").substring(0,10); // First ten digits of input only
      var areacode = input.substring(0,3);
      var prefix = input.substring(3,6);
      var number = input.substring(6,10);

      if(input.length > 6){target.value = `(${areacode}) ${prefix}-${number}`;}
      else if(input.length > 3){target.value = `(${areacode}) ${prefix}`;}
      else if(input.length > 0){target.value = `(${areacode}`;}
  }

  var inputElement = document.getElementById("form-phone");
  if (inputElement) { // verify element exists
      inputElement.addEventListener("keydown",enforceFormat);
      inputElement.addEventListener("keyup",formatToPhone);
  }

  ////////////////////////////


  // validate zip code is in service area //

  function validateZipCode(userZip) {
      $.ajax({
          url: lpc_ajax_var.locations_data,
          dataType: "json",
          method: "GET",
          async: true,
          data: { },
          success: function (data) {
              var isInServiceArea = 0;
              for (var i = 0; i < data.length; i++){              // iterate through objects
                  if (data[i].serviceareas.includes(userZip)){    // look for the entry with a matching service area zip code
                      isInServiceArea = 1;
                  }
              }
              if (isInServiceArea === 0) {
                  quoteFormModifier(userZip,false);
              } else {
                  quoteFormModifier(userZip,true);
              }
          },
          error: function (xhr, exception) {
              // error
          }
      });
  }

  //////////////////////////////////////////


  // validate email address
  function isValidEmail(email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
  }


  $("#zip-code").on("input", function(){                                      // listen for changes in the zip code field
      if(!$.isNumeric($(this).val())) {                                       // if there are any non-numeric characters, empty the input field
          $("#zip-code").val("");
      }
      if ( $(this).val().length > 5 ) {                                       // if input goes over 5 digits, truncate it and update form field
          var correctedZip = $(this).val().substring(0,5);
          $("#zip-code").val(correctedZip);
      }
      if ( ( $(this).val().length == 5 ) && $.isNumeric($(this).val()) ) {    // correct value, check that it is numeric and fire up the form modifier
          validateZipCode($(this).val());
      }
  });


  function quoteFormModifier(userZip,inServiceArea) {
      if (inServiceArea === false){                                           // userZip is not in service area
          $(".step-one .pre-text").hide(0);                                   // hide default text
          $(".step-one .pre-text.error").width(widthDefaultText).show(0);     // widthDefaultText keeps the element the same width as default so that there's no jarring motion
          $(".lpc-iq.step-one").css("background-color","#a52a2a");
      } else {                                                                // userZip is in service area
          $(".lpc-iq.step-one").css("background-color","#9c481a");            // change background color back in case zip code is corrected
          $(".step-one").animate({
              height: "0"                                                     // first close the entire container while the other adjustments happen in the background
              }, 200,
              function() {
                  $(".step-one .pre-text").hide(0);                           // hide original text
                  $(".step-one form").hide(0);                                // hide form block
                  $(".step-one .post-text").width(widthText).show(0);         // show instruction text

                  // adjustments to .step-one to set correct height based upon child elements
                  var child_h2_height  = $(".step-one .flex h2").height();            // get height of h2
                  var post_text_height = $(".step-one .flex .post-text").height();    // get height of .post-text
                  var step_one_height  = (child_h2_height + post_text_height + 65)    // add 65 to accommodate margins
                  var screen_width = window.innerWidth;                               // get width of current window
                  if (screen_width > 767) {                                           // for desktop version, where all elements are inline, don't add the .post-text height.
                      step_one_height = (child_h2_height + 45);                       // also reduce margin by 20 since the elements aren't stacked in this view
                  }

                  $(".step-one .inner>div").css("top","20px");

                  $(".step-one").animate({height: (step_one_height) + "px"}, {duration: 200});         // reopen element after adjustments are completed
                  $("#zip-code-2").val( userZip );                                    // update step two zip code field
                  $( ".step-two" ).delay(0).slideDown(500);
              }
          );

          if ( $(".lpc-header.ekit-sticky").length ) {                        // slide form to top of page, adjusting for sticky menu, if visible
              $([document.documentElement, document.body]).animate({
                      scrollTop: ( $(".step-one").offset().top - $(".lpc-header.ekit-sticky").height() )
              }, 500);
          } else {
              $([document.documentElement, document.body]).animate({
                      scrollTop: ( $(".step-one").offset().top )
              }, 500);
          }
      }

  }


  function finalMessage(finalMsg) {

      // final step, close up form elements and display final message
      $( ".step-three" ).slideUp(250);                                    // slide up the last form section
      $( ".step-two" ).slideUp(250);                                      // slide up the middle form section
      widthDefaultText = Math.ceil( $(".post-text").width() );            // get width of text area so that it doesn't change as the text updates
      $(".lpc-iq.step-one").animate({height: "111px"}, {duration: 500});  // reopen element after adjustments are completed
      $(".lpc-iq.step-one").css("background-color","#648000");            // change section background color to a green
      if ( $(".lpc-header.ekit-sticky").length ) {                        // slide form to top of page, adjusting for sticky menu, if visible
          $([document.documentElement, document.body]).animate({
                  scrollTop: ( $(".step-one").offset().top - $(".lpc-header.ekit-sticky").height() )
          }, 500);
      } else {                                                            // no sticky menu, exclude its height
          $([document.documentElement, document.body]).animate({
                  scrollTop: ( $(".step-one").offset().top )
          }, 500);
      }
      $(".step-one .post-text").hide(0);                                  // hide default text
      $(".step-one .post-text-complete").width(widthDefaultText).show(0); // show thank you text
      $(".step-one .post-text-complete").text(finalMsg);                  // update final message (success/error)

      // adjustments to .step-one to set correct height based upon child elements
      var child_h2_height  = $(".step-one .flex h2").height();            // get height of h2
      var post_text_height = $(".step-one .flex .post-text-complete").height();    // get height of .post-text
      var step_one_height  = (child_h2_height + post_text_height + 65)    // add 65 to accommodate margins
      var screen_width = window.innerWidth;                               // get width of current window
      if (screen_width > 767) {                                           // for desktop version, where all elements are inline, don't add the .post-text height.
          step_one_height = (child_h2_height + 45);                       // also reduce margin by 20 since the elements aren't stacked in this view
      }
      $(".step-one").animate({height: (step_one_height) + "px"}, {duration: 200});         // reopen element after adjustments are completed

  }


  $("#iq-continue").on("click", function(){

      // validate form fields //

      function formValidator(name) {                                      // adds "error" class to form fields that need to be filled in or corrected
          $("#" + name).addClass("error");
      }
      $("#form-name,#form-email,#form-phone").on("input", function() {    // clear "error" class as user types in these fields
          if ( $(this).hasClass("error") ) {
              $(this).removeClass("error");
          }
      });
      var errorCount      = 0;
      var addedMsg        = "";
      var getName         = $("#form-name").val();
      var getEmail        = $("#form-email").val();
      var getPhone        = $("#form-phone").val();
      var getNameLen      = getName.replace(/\s+/g, "").length;
      if (getNameLen < 1) {                                               // make sure name is at least 1 character long
          formValidator("form-name");
          addedMsg += " (missing name)";
          errorCount++;
      }
      var getEmailLen     = getEmail.replace(/\s+/g, "").length;
      if ( (!getEmailLen > 4) || (!isValidEmail(getEmail)) ) {            // email address must be at least 5 characters, and be valid
          formValidator("form-email");
          addedMsg += " (invalid email address)";
          errorCount++;
      }
      var getPhoneLen      = getPhone.replace(/\s+/g, "").length;
      if (getPhoneLen != "13") {                                          // correctly formed phone number - (123) 456-7890 - minus spaces is 13 characters long
          formValidator("form-phone");
          addedMsg += " (invalid phone number)";
          errorCount++;
      }
      if (errorCount > 0) {
          $(".error-msg").text("Please complete required fields");        // default error message
          if (addedMsg) {
              $(".error-msg").append(addedMsg);                           // add custom messages for individual fields
          }
          $(".error-msg").show(100);
          return false;
      } else {
          if( $(".error-msg").is(":visible") ){                           // on next form submit, if there are no errors and the error msg is still visible, hide it
              $(".error-msg").hide(100);
          }
      }

      ///////////////////////
      // execute recaptcha //

      grecaptcha.ready(function() {
          grecaptcha
              .execute("6LfcbpckAAAAAG9SSZOdyqlbG7UZKtyZbg8ez9Oo", {
                  action: "contact"
          })
          .then(function(token){
              let recaptchaResponse = document.getElementById("recaptcha-response");
              recaptchaResponse.value = token;
          })
          .then(function(){

              // moving on... //
              $("#zip-code-2").prop("disabled",false);    // enable zip field, otherwise serialize() will ignore it
              var formData = $('#iq-s2').serialize();     // serialize form data to pass to handler
              //console.log(formData);

              $.ajax({
                  method: "POST",
                  url: lpc_ajax_var.url,
                  data: {
                      formData: formData,
                      action: "online_quote_handler",
                      nonce: lpc_ajax_var.nonce,
                      step:   "primary",
                      contentType: "application/json"
                  },
                  success: function (resp) {

                      //console.log(resp);

                      // validate that data returned isn't missing requestData (includes bug type requested), otherwise display default message
                      if( (resp !== null) && (resp !== '') && (resp !== undefined) ) {
                          var respParsed  = JSON.parse(resp);
                          //console.log(respParsed);

                          if( (respParsed.errorData !== null) && (respParsed.errorData !== '') && (respParsed.errorData !== undefined) ) {    // user exceeded recaptcha threshold
                              finalMessage(formBlockError + '(0)');
                              return false;
                          }

                          // check for zips that should show default "we'll get back to you" message //
                          let curZip = $('#iq-s2 #zip-code-2').val(); // grab user's zip code
                          const deadZips = ["92301"];                 // array of dead zip codes that get generic message
                          if (deadZips.includes(curZip)) {
                              finalMessage(formBlockDefault);
                              return false;
                          }

                          if( (respParsed.requestData !== null) && (respParsed.requestData !== '') && (respParsed.requestData !== undefined) ) {
                              var requestDataLen  = Object.keys(respParsed.requestData).length;   // requestData object

                              if (requestDataLen > 0) {        // potentially found pest data

                                  var bugType     = respParsed.requestData[0].bugType;
                                  var propType    = respParsed.requestData[0].propType;
                                  var propSqFt    = respParsed.requestData[0].propSqFt;
                                  var branchCode  = respParsed.requestData[0].branchCode;
                                  if( (bugType    !== null) &&
                                      (bugType    !== '') &&
                                      (bugType    !== undefined) &&
                                      (propType   !== null) &&
                                      (propType   !== '') &&
                                      (propType   !== undefined) &&
                                      (propSqFt   !== null) &&
                                      (propSqFt   !== '') &&
                                      (propSqFt   !== undefined) &&
                                      (branchCode !== null) &&
                                      (branchCode !== '') &&
                                      (branchCode !== undefined) &&
                                      (propType   !== 'Commercial/Multi-Family')
                                      )
                                  {   // bugType exists, continue to serviceTypes

                                      if( (respParsed.serviceTypes !== null) && (respParsed.serviceTypes !== '') && (respParsed.serviceTypes !== undefined) ) {
                                          var serviceTypesLen = Object.keys(respParsed.serviceTypes).length;  // serviceTypes object

                                          if (serviceTypesLen > 0) {  // services were returned, prep form update

                                              // iterate through json object
                                              $.each(respParsed.serviceTypes, function(index, element) {
                                                  //console.log(element.title);
                                                  var prePrice = "";
                                                  if ( (element.pricing_2.length > 0) && (element.pricing_2_text.length > 0) ) {
                                                      prePrice = "<div class=\"clean-out\">" + element.pricing_2_text + " $" + element.pricing_2 + ", then:</div>";
                                                  }
                                                  formBlock +=
                                                      "<div class=\"flex-container service-type\">" +
                                                          "<div class=\"upper\">" +
                                                              "<h3>" + element.title + "</h3>" +
                                                              "<h5>" + element.subtitle + "</h5>" +
                                                          "</div>" +
                                                          "<div class=\"lower\">" +
                                                              element.pretext +
                                                              prePrice +
                                                              "<div class=\"price\">" +
                                                                  "<strong><em>$</em>" + element.pricing_1 + "</strong>" +
                                                                  "<span>" + element.pricing_1_text + "</span>" +
                                                              "</div>" +
                                                              "<div class=\"description\">" +
                                                                  element.posttext +
                                                              "</div>" +
                                                              "<button class=\"select-btn\">Select</button>" +
                                                          "</div>" +
                                                      "</div>";
                                              });

                                          } else {                    // no services found
                                              finalMessage(formBlockDefault);
                                          }

                                      } else {                        // no serviceTypes passed
                                          finalMessage(formBlockDefault);
                                      }

                                  } else {                            // at least one of these empty [bugType,propType,propSqFt,branchCode] or propType is commercial
                                      finalMessage(formBlockDefault);
                                  }

                              } else {                                // no original pest data passed, show default message
                                  finalMessage(formBlockDefault);
                              }

                          } else {                                    // requestData zero length
                              finalMessage(formBlockDefault);
                          }

                      } else {                                        // response empty
                          finalMessage(formBlockDefault);
                      }

                      $(".step-three .inner .flex.main").html(formBlock);

                      if (["Rats", "Mice"].indexOf(bugType) > -1) {
                          $(".step-three .inner .main-title").html("Select Your Preferred Service Option for Rodents + General Pests");
                      }
                      if (bugType === "Termites") {
                          $(".step-three .inner .main-title").html("Select Your Preferred Termite Service Option");
                          $(".step-three .inner .secondary-title").show();
                          $(".step-three .inner .flex.main-switch").html(formBlock);
                          $(".step-three .inner .flex.main").hide();
                          $(".step-three .inner .flex.main-switch").show();
                      }
                      $('.lower li').prepend('<span class="fa-li"><i class="fas fa-check"></i></span>');  // add fancy checkmark to all list items

                  },
                  error: function() {
                      //console.log("getUserZip() primary error");
                  }
              });

          })
          .then(function(){
                      // hide and disable form elements
                      $( "#iq-continue, .agree.step-two" ).slideUp(50);
                      $( ".step-three" ).slideDown(250);

                      $(".step-two input, .step-two select").each(function() {
                          $(this).prop("disabled",true);
                      })

                      function adjustScheduleBoxes() {    // set service boxes to equal height
                          var boxHeight = -1;
                          $(".step-three .flex.main .flex-container").each(function() {
                              boxHeight = boxHeight > $(this).height() ? boxHeight : $(this).height();
                          });

                          $(".step-three .flex.main .flex-container").each(function() {
                              $(this).height(boxHeight);
                              //$(".step-three .flex.main .flex-container:nth-child(2)").height(boxHeight + 60); // optional: set middle box to be taller (set margin-top:-30px for this element)
                          });
                      }
                      setTimeout(adjustScheduleBoxes, 250);
          })
          .catch(error => {
              console.log (error);
          })
      })

      ///////////////////////

  });


  $("#iq-submit").on("click", function(event){    // handle final form submit

      event.preventDefault();

      var formData = $('#iq-s3').serialize();
      var finalMsg = "";
      //console.log(formData);

      $.ajax({
          method: "POST",
          url: lpc_ajax_var.url,
          dataType: "json",
          data: {
              formData: formData,
              action: "online_quote_handler",
              nonce: lpc_ajax_var.nonce,
              step:   "secondary"
          },
          success: function (data) {
              //console.log(JSON.stringify(data));

              if (data.success === false){
                  finalMsg = formBlockError + '(1)';
              } else {
                  finalMsg = formBlockDefault;
              }

              finalMessage(finalMsg);

          },
          error: function(request, status, error) {
              finalMsg = formBlockError + '(2)';
              finalMessage(finalMsg);
          }
      });

  });

  $(".step-three").on('click', ".service-type .select-btn", function(){       // handler for select buttons (step-three)

      event.preventDefault();

      if ($(this).parents(".service-type.termite-inspection").length) {       // selective behavior when clicking the Termite Inspection button
          if ($(this).hasClass("enabled")){
              $(this).removeClass("enabled");                                 // if button currently highlighted, remove enabled class
              $("#iq-s3 input[name=add-termite-inspection]").val('');
          } else {
              $(this).addClass("enabled");                                    // otherwise add enabled class to highlight button
              $("#iq-s3 input[name=add-termite-inspection]").val('y');
              if ($(".service-type.termite-inspection-escrow .select-btn").hasClass("enabled")){
                  $(".service-type.termite-inspection-escrow .select-btn").removeClass("enabled")
                  $("#iq-s3 input[name=add-termite-inspection-escrow]").val('');
              }
          }
      } else if ($(this).parents(".service-type.termite-inspection-escrow").length) {       // selective behavior when clicking the Termite Inspection (escrow) button
          if ($(this).hasClass("enabled")){
              $(this).removeClass("enabled");                                 // if button currently highlighted, remove enabled class
              $("#iq-s3 input[name=add-termite-inspection-escrow]").val('');
          } else {
              $(this).addClass("enabled");                                    // otherwise add enabled class to highlight button
              $("#iq-s3 input[name=add-termite-inspection-escrow]").val('y');
              if ($(".service-type.termite-inspection .select-btn").hasClass("enabled")){
                  $(".service-type.termite-inspection .select-btn").removeClass("enabled")
                  $("#iq-s3 input[name=add-termite-inspection]").val('');
              }
          }
      } else {                                                            // otherwise this is another service type, which can only be selected one at a time
          if ($(this).hasClass("enabled")){
              $(this).removeClass("enabled");
          } else {
              $(".service-type .select-btn").not(".service-type.termite-inspection .select-btn").not(".service-type.termite-inspection-escrow .select-btn").removeClass("enabled");  // don't modify the termite buttons at all here
              $(this).addClass("enabled");
              // extract values for service name and pricing, insert into service-type hidden field for later use
              var serviceName         = $(this).closest('.service-type').find('.upper h3').text(),
                  serviceDuration     = $(this).closest('.service-type').find('.upper h5').text(),
                  servicePriceAmt     = $(this).closest('.service-type').find('.lower .price strong').text().trim(),
                  servicePriceInfo    = $(this).closest('.service-type').find('.lower .price span').text().trim()
              ;
              $("#iq-s3 input[name=service-type]").val("");                   // reset hidden field for 'service-type'
              $("#iq-s3 input[name=service-type]").val(serviceName + " / " + serviceDuration + " (" + servicePriceAmt + " " + servicePriceInfo + ")");
          }
      }

  });

});
