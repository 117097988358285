/**
 * JavaScript controller for shortcode: map
 */
Mmg.addShortcode('map', '.sc-map', (el, model, $, selector, className) => {

	const $this = $(el);
	const $map = $this.find(`${selector}__map`);
	const $cardContainer = $this.find(`${selector}__card-container`);
	const $card = $this.find(`${selector}__card`);
	const $cardClose = $this.find(`${selector}__card-close`);
	const $filters = $this.find(`${selector}__filters`);
	const $filterMenu = $this.find(`${selector}__filter-menu`);

	// For tuning/troubleshooting icon placement you can use an opaque image
	// in place of the transparent shim.
	const shimUrl = 'https://maps.gstatic.com/mapfiles/transparent.png';
	const templates = {
		'filterCheckbox': $this.find(`${selector}__tpl-filter-checkbox`).html(),
		'card': $this.find(`${selector}__tpl-card`).html(),
	};
	const selectors = {
		'filterCheckbox': `${selector}__filter-checkbox`,
	};

	const apiParams = model.api_params ?? {};

	let googleMapRouteOptions = {
		strokeColor: '#0000FF',
		strokeOpacity: 0.50,
		strokeWeight: 2
	}
	let locations;
	let map;
	let activeMarker;
	let markerState = {};
	let clusterOptions = {
		gridSize: 20,
		maxZoom: 15,
		styles: [{
			url: shimUrl,
			height: 36,
			width: 24,
			anchorText: [-5, 0],
			textColor: '#000',
			textSize: 10,
			anchorIcon: [36, 12],
		}]
	};

	let callbackFilters = $this.data('filters') || {};

	// Lazy loaded values
	let customClusterOptions;
	let anchorMmgOptions;
	let googleMapOptions;
	let bounds;
	let anchorOptions;


	/**
	 * Initialize the widget
	 */
	(function init() {
		$(window).on("show.map", loadMap);

		if (model.load_event === 'ready') {
			$(document).ready(loadMap);
		} else {
			$(document).one(model.load_event, loadMap);
		}
		
	})();


	/**
	 * Load Google map libraries
	 */
	function loadMap() {
		google.maps.importLibrary("maps").then(onGoogleMapsLoaded);
	}


	/**
	 * When google maps library is loaded, load Icon library
	 * and locations data.
	 */
	function onGoogleMapsLoaded() {
		initializeGoogleOptions(); 

		$.when(
			$.getScript(model.google_maps_dependents),
			getRemoteData(apiParams)
		).done((result1, result2) => onDataLoaded(result2[0])); 
	};


	/**
	 * Initialize values that depend on google.maps
	 */
	function initializeGoogleOptions() {
		customClusterOptions = Mmg['google-map-cluster-options']() || {};
		anchorMmgOptions = Mmg['google-map-anchor-options']() || {};
		googleMapOptions = Mmg['google-map-options']() || {};
		bounds = new google.maps.LatLngBounds();
		anchorOptions = {
			url: shimUrl,
			size: new google.maps.Size(24, 36),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(12, 36),
			scaledSize: new google.maps.Size(24, 36),
		};
	}


	/**
	 * [onDataLoaded description]
	 * @param  {[type]} data [description]
	 * @return {[type]}      [description]
	 */
	function onDataLoaded(data){
		renderFilters(data);
		renderMarkers(data);
		addEvents();
		$(window).trigger('shown.map');
	}


	/**
	 * Events
	 */
	function addEvents(){

		// Catch filter menu clicks to:
		// 1. Stop bootstrap's default behavior of closing the menu
		// 2. Bind events to the filter's checkboxes
		//
		// We do this in a single event handler because event.stopPropagation
		// in one handler prevents us from catching the bubbled up event in
		// another handler.
		$filterMenu.click(event => {
			if($(event.target).is(selectors.filterCheckbox)){
				onFilterClick.bind(event.target)(event);
			}
			event.stopPropagation();
		});


		const deferedUpdateMarkerVisibility = () => {
			updateMarkerVisibility();
			setTimeout(updateMarkerVisibility);
			setTimeout(updateMarkerVisibility, 100)
		};

		// Don't show filtered markers when map view changes.
		// This is technically challenging because the marker cluster lib is
		// also trying to change marker and cluster visibility based on the
		// same events.
		map.addListener('center_changed', deferedUpdateMarkerVisibility);
		map.addListener('zoom_changed', deferedUpdateMarkerVisibility);
		map.addListener('bounds_changed', deferedUpdateMarkerVisibility);
		map.addListener('dragstart', deferedUpdateMarkerVisibility);
		map.addListener('drag', deferedUpdateMarkerVisibility);
		map.addListener('dragend', deferedUpdateMarkerVisibility);
		map.addListener('idle', deferedUpdateMarkerVisibility);
		map.addListener('tilesloaded', addAltImg);

		$cardClose.click(hideCard);
	}


	/**
	 * Add InfoWindow and card controls to the marker
	 * @param {object} marker The marker instance
	 * @param {object} location The location associated with the marker
	 */
	function addMarkerEvents(marker, location){

		var infowindow = new google.maps.InfoWindow({
			content: location.name,
			pixelOffset: new google.maps.Size(3, -1)
		});

		marker.addListener('mouseover', () => infowindow.open(map, marker));
		marker.addListener('mouseout', () => infowindow.close(map, marker));
		marker.addListener('click', () => toggleCard(marker, location));
	}


	/**
	 * [renderMap description]
	 * @return {google.maps.Map} [description]
	 */
	function renderMap(){

		const options = {
			zoom: 10,
			center: new google.maps.LatLng(model.region.lat, model.region.lng),
			scrollwheel: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.TOP_RIGHT
			},
			styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}]
		};

		// merge settings
		const mapOptions = $.extend({}, options, googleMapOptions);

		return new google.maps.Map($map[0], mapOptions);
	}


	/**
	 * [getRemoteData description]
	 * @return {[type]} [description]
	 */
	function getRemoteData(data) {
		// init map here to reset
		// and re-plot markers
		map = renderMap();

		return $.getJSON(model.api_url, data);
	}


	/**
	 * [renderMarkers description]
	 * @param  {[type]} data [description]
	 * @return {[type]}      [description]
	 */
	function renderMarkers(data){
		const markers = $.map(data.locations, (locations, type) => {
			return model.render_type === 'route'
				?  renderRoute(type, locations)
				:  renderCluster(type, locations);
		});

		map.fitBounds(bounds);

		// when only a single marker is present 
		// fix the zoom
		if (markers[0].markers_
			&& markers[0].markers_.length == 1
		) {
			map.setZoom(10);
		}

		if (model.zoom_level) {
			map.setZoom(model.zoom_level);
		}

		return markers;
	}


	/**
	 * Render a route of markers
	 * @param  {[type]} type [description]
	 * @param  {[type]} locations [description]
	 * @return {[type]}      [description]
	 */
	function renderRoute(type, locations){
		const markers = processMarkers(type, locations);
		const options = $.extend({}, googleMapRouteOptions, googleMapOptions['routeStyles']);

		let path = drawCurvedPath(markers[0].position, markers[1].position);

		new google.maps.Polyline({
			geodesic: true,
			map: map,
			path: path,
			strokeColor: options.strokeColor,
			strokeOpacity: options.strokeOpacity,
			strokeWeight: options.strokeWeight,
		});

		return markers
	}


	/**
	 * Draw curved path for a route
	 * @param  object location01  Google map maker object.
	 * @param  object location02  Google map maker object.
	 * @return object             Curved path to pass to google maps.
	 */
  function drawCurvedPath(location1, location2){
    let lineLength = google.maps.geometry.spherical.computeDistanceBetween(location1, location2);
    let lineHeading = google.maps.geometry.spherical.computeHeading(location1, location2);
    let lineHeading1 = '';
    let lineHeading2 = '';

    // higher the number, lower the curvature
    const curvature = 3;

    // In degrees, controlls which side to draw the line
    // this is done so the arch is not drawn upside down.
    const headingPositive1 = 35;
    const headingPositive2 = 145;
    const headingNegative1 = 35;
    const headingNegative2 = 145;

    // This controlls the degrees clockwise where the line is drawn from the maker.
    // Its basically moving the two mid points on the map betwen the two locations
    if (lineHeading < 0) {
      lineHeading1 = lineHeading + headingNegative1;
      lineHeading2 = lineHeading + headingNegative2;
    }
    else {
      lineHeading1 = lineHeading - headingPositive1;
      lineHeading2 = lineHeading - headingPositive2;
    }

    // Calculate the two mid points betwen location1 to location2
    let midPointA = google.maps.geometry.spherical.computeOffset(location1, lineLength / curvature, lineHeading1);
    let midPointB = google.maps.geometry.spherical.computeOffset(location2, lineLength / curvature, lineHeading2);

    return calculateCurvedPath(location1, midPointA, midPointB, location2);
  }


  /**
	 * Calculate curved path using Bézier curve.
   *
	 * @param  object point1  Google map maker object.
	 * @param  object point2  Google map maker object.
	 * @param  object point3  Google map maker object.
	 * @param  object point4  Google map maker object.
	 * @return object         Curved path to pass to google maps.
	 */
  function calculateCurvedPath(point1, point2, point3, point4) {
    const lat1 = point1.lat();
    const long1 = point1.lng();
    const lat2 = point2.lat();
    const long2 = point2.lng();
    const lat3 = point3.lat();
    const long3 = point3.lng();
    const lat4 = point4.lat();
    const long4 = point4.lng();

    let points = [];
    let path = [];
    // sets how smooth the curve is. 0.01 is smoothest
    const resolution = 0.01;

    /**
     * A Bézier curve is defined by four control points P1, P2, P3, and P4.
     * P1 and P4 are the start and the end of the curve and,
     * P2 and P3 represents the mid positions for the curve.
    */
    const cubicBezier = {
      B1: function(t) {
        return t * t * t;
      },
      B2: function(t) {
        return 3 * t * t * (1 - t);
      },
      B3: function(t) {
        return 3 * t * (1 - t) * (1 - t);
      },
      B4: function(t) {
        return (1 - t) * (1 - t) * (1 - t);
      },
      getBezier: function(C1, C2, C3, C4, percent) {
        let pos = {};
        pos.x = C1.x * this.B1(percent) + C2.x * this.B2(percent) + C3.x * this.B3(percent) + C4.x * this.B4(percent);
        pos.y = C1.y * this.B1(percent) + C2.y * this.B2(percent) + C3.y * this.B3(percent) + C4.y * this.B4(percent);
        return pos;
      }
    };

    for (point = 0; point <= 1; point+=resolution) {
      points.push(cubicBezier.getBezier({
        x: lat1,
        y: long1
      }, {
        x: lat2,
        y: long2
      }, {
        x: lat3,
        y: long3
      }, {
        x: lat4,
        y: long4
      }, point));
    }

    for (i = 0; i < points.length - 1; i++) {
      path.push(new google.maps.LatLng(points[i].x, points[i].y));
      path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
    }

    return path;
  };


	/**
	 * Render a cluster of markers of the given type
	 * @param  {[type]} type [description]
	 * @param  {[type]} locations [description]
	 * @return {[type]}      [description]
	 */
	function renderCluster(type, locations){
		const typeClass = type.replace(/\s+/g, '');
		const markers = processMarkers(type, locations);

		clusterOptions.clusterClass = `${className}__cluster ${className}__cluster--${typeClass}`;

		const options = $.extend({}, clusterOptions, customClusterOptions);

		return new MarkerClusterer(map, markers, options);
	}

	/**
	 * Process markers
	 * @param  {[type]} type [description]
	 * @param  {[type]} locations [description]
	 * @return {[type]}      [description]
	 */
  function processMarkers(type, locations){
		
		const processMarkers = callbackFilters.processMarkers
		? callbackFilters.processMarkers(type, locations, map, bounds)
		: getMarkers(type, locations);
		
		return processMarkers;
  }

	/**
	 * [renderFilters description]
	 * @return {[type]} [description]
	 */
	function renderFilters(data){

		let filters;

		if(Object.keys(data.locations).length < 2){
			return;
		}

		filters = $.map(data.locations, (locations, type) => {
			return renderFilter(type);
		});

		$filterMenu.html(filters.join(''));
		$filters.show();
	}


	/**
	 * [renderFilter description]
	 * @param  {[type]} type [description]
	 * @return {[type]}      [description]
	 */
	function renderFilter(type){
		return Mustache.render(templates.filterCheckbox, {
			type: type,
			class: type.replace(/\s+/g, ''),
			checked: 'checked="checked"',
		});
	}


	/**
	 * [onFilterClick description]
	 * @param  {[type]} event [description]
	 * @return {[type]}       [description]
	 */
	function onFilterClick(event){
		toggleMarkers(this.name, this.checked);
	}


	/**
	 * [updateMarkerVisibility description]
	 * @return {[type]} [description]
	 */
	function updateMarkerVisibility(){
		$.each(markerState, (type, isVisible) => {
			toggleMarkers(type, isVisible);
		});
	}


	/**
	 * [toggleMarkers description]
	 * @param  {[type]}  type      [description]
	 * @param  {Boolean} isVisible [description]
	 * @return {[type]}            [description]
	 */
	function toggleMarkers(type, isVisible){
		const markers = $this.find(`${selector}__marker--${type}`);//.parent('.map-icon-label');
		const clusters = $this.find(`${selector}__cluster--${type}`).not(':empty');
		const className = 'is-hidden';

		$(markers).toggleClass(className, !isVisible);
		$(clusters).toggleClass(className, !isVisible);

		setMarkerState(type, isVisible);
	}


	/**
	 * [setMarkerState description]
	 * @param {[type]}  type      [description]
	 * @param {Boolean} isVisible [description]
	 */
	function setMarkerState(type, isVisible){
		markerState[type] = !!isVisible;
	}


	/**
	 * [toggleCard description]
	 * @param  {[type]} marker   [description]
	 * @param  {[type]} location [description]
	 * @return {[type]}          [description]
	 */
	function toggleCard(marker, location){
		if(activeMarker === marker){
			activeMarker = null;
			hideCard();
		} else {
      activeMarker = marker;

      showCard(marker, location);
    }
  }


  /**
	 * Applies filters when available before rendering card
	 * @param  object marker   Google map marker objects
	 * @param  object location Location object with data that displays in card
	 */
  function showCard(marker, location) {
    doShowCard(marker);

    if(typeof callbackFilters.onShowCard === "function"){
      callbackFilters.onShowCard(location, () => {
        renderCard(location);
      });
    } else {
      renderCard(location);
    }
  }


	/**
	 * [doShowCard description]
	 * @param  {[type]} marker   [description]
	 */
	function doShowCard(marker){
    $card.empty();

    $map.one('transitionend', () => {
      google.maps.event.trigger(map, 'resize');
      map.panTo(marker.position);
      $map.off('transitionend');
    });

    if($map.hasClass('has-card')){
      $map.trigger('transitionend');
    }

    $cardContainer.addClass('is-visible');
    $map.addClass('has-card');
  }


  /**
	 * [renderCard description]
	 * @param  {[type]} location [description]
	 */
	function renderCard(location){
    $card.html(Mustache.render(
      templates.card,
      getCardModel(location)
    ));
	}


	/**
	 * [hideCard description]
	 * @return {[type]} [description]
	 */
	function hideCard(){
		$cardContainer.removeClass('is-visible');
		$map.removeClass('has-card');

		$map.one('transitionend', () => {
			google.maps.event.trigger(map, 'resize');
		});
	}


	/**
	 * [getCardModel description]
	 * @param  {[type]} location [description]
	 * @return {[type]}          [description]
	 */
	function getCardModel(location){
		return $.extend(location, model.card_model);
	}

	/**
	 * Add Alt attribute to transparent markers
	 * this is mainly implemented for WCAG compliance
	 * https://www.w3.org/WAI/standards-guidelines/wcag/
	 */
	function addAltImg(){
    $(this.getDiv()).find("img").each(function(i, eimg){
      if(!eimg.alt || eimg.alt === ""){
        eimg.alt = "Map marker";
      }
    });
	}

	/**
	* Get and process markers from locations
	* @param  {string} type [description]
	* @param  {object} locations [description]
	*/
	function getMarkers(type, locations) {
		const typeClass = type.replace(/\s+/g, '');
		const markerIcon = $.extend({}, anchorOptions, anchorMmgOptions);
		const markers = $.map(locations, location => {

			const latLng = new google.maps.LatLng(
							location.latitude,
							location.longitude
			);
			const marker = new Marker({
							map: map,
							position: latLng,
							icon: markerIcon,
							map_icon_label: `<span class="${className}__marker ${className}__marker--${typeClass}"></span>`,
			});

			addMarkerEvents(marker, location);
			bounds.extend(latLng);

			return marker;
		});

		return markers;
	};

});
