/**
 *	Clamped
 *	A component for expanding/collapsing truncated intro text.
 */
jQuery(function($){
$(".clamped").each(function(){

	const $this = $(this);
	const shownClass = 'show';


	/**
	 *	Initialize
	 */
	(function init(){
		// Intro event handlers
		$this.on('click', '[class*="__intro-toggle--more"]', handleShowMore);
		$this.on('click', '[class*="__intro-toggle--less"]', handleShowLess);

		// Content block event handlers
		$this.on('click', '[class*="__block-body-toggle--more"]', handleShowMore);
		$this.on('click', '[class*="__block-body-toggle--less"]', handleShowLess);
	})();

	/**
	 * Show expanded intro
	 * @param {*} event
	 */
	function handleShowMore(event) {
		event.preventDefault();
		$this.addClass(shownClass);
	}
	
	
	/**
	 * Show collapsed or truncated intro
	 * @param {*} event
	 */
	function handleShowLess(event) {
		event.preventDefault();
		$this.removeClass(shownClass);
	}

});
});
