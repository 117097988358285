/**
 * A Javascript geoIP client.
 * @param {string} method An optional geoip method to get extended results.
 *              Valid values are empty or 'with-airports'.
 * @return {Promise}
 *
 *      Examples:
 *      Mmg.geoip().done( doSomethingWithGeoData );
 *      Mmg.geoip('with-airports').done( doSomethingWithAirportData );
 */
Mmg.geoip = (method = '') => {

    return jQuery.ajax({
        url: `https://api.geoip.mmg.zone/${method}`,
        cache: true,
    })
}
