/*
* JavaScript for Header
*/
jQuery(function($){

    const selector = '.header';
    const $this = $(selector);
    const $body = $('body');
    const $searchModal = $this.find('.header__modal');

    (function init(){
        addEvents();
        addScript();
    })();

    function addEvents() {
        $searchModal.on('show.bs.modal', handleShownModal)
    }

    // Fix bootstrap modal-backdrop issue. To adapt it on scroll
    function handleShownModal(event) {
        $($searchModal).appendTo($body);
    }

    function addScript() {
        var ada_script = document.createElement('script');
        ada_script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        ada_script.async = true;
        ada_script.onload = function(){
            acsbJS.init({
                statementLink : '',
                footerHtml : '',
                hideMobile : false,
                hideTrigger : false,
                disableBgProcess : false,
                language : 'en',
                position : 'right',
                leadColor : '#146ff8',
                triggerColor : '#146ff8',
                triggerRadius : '50%',
                triggerPositionX : 'left',
                triggerPositionY : 'bottom',
                triggerIcon : 'people',
                triggerSize : 'medium',
                triggerOffsetX : 20,
                triggerOffsetY : 20,
                mobile : {
                    triggerSize : 'medium',
                    triggerPositionX : 'left',
                    triggerPositionY : 'bottom',
                    triggerOffsetX : 10,
                    triggerOffsetY : 75,
                    triggerRadius : '50%'
                }
            });
        };
        var h = document.querySelector('head') || document.body;
        h.appendChild(ada_script);
    }

});
