/**
 *
 * Shortcode: modal_guarantee
 *
 */

Mmg.addShortcode(

  /**
   *
   * @param {string} shortcodeName The name of the shortcode
   * @param {string} selector The selector used to locate this shortcode in the DOM
   * @param {callable} callback The callback function applied to every element matching the selector
   */
  'modal_guarantee',
  '.sc-modal-guarantee',

  /**
   *
   * Shortcode callback
   *
   * The shortcode callback receives the following parameters:
   *
   * @param {Element} el The shortcode's DOM element
   * @param {object} model The data model passed to the shortcode instance in data-json attribute
   * @param {jQuery} $ The callback function applied to every element matching the selector
   * @param {string} selector The shortcode selector that was passed to addShortcode
   * @param {string} className The name of the shortcode class
   */
  (el, model, $, selector, className) => {

    // Shortcode Declarations
    const $this = $(el);

    /**
     * Initialize the widget
     */
    (function init(){
      $this.on('click', handleClick);
    })();


    /**
     * Show correct modal on anchor click
     * @param {*} event
     */
    function handleClick (event) {
      const view = $(event.target).attr('href').replace('#modal-', '');
      
      event.preventDefault();
      Mmg.Modal.show('', '...').addClass('loading');
      $.ajax({
        url: model.api_url,
        data: { view },
      })
      .done(data => Mmg.Modal.update(data.content).removeClass('loading'));
    }
  }
);
