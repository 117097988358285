/**
 * JavaScript controller for shortcode: [links.map]
 */
Mmg.addShortcode('links.map', '.sc-links.map', (el, model, $, selector, className) => {

  const MAX_INITIAL_ZOOM_LEVEL = 13;
  const map_selector = 'sc-links__map';
	const $this = $(el);

  let $map;
	let map;
  let bounds = new google.maps.LatLngBounds();

	/**
	 * Initialize the widget
	 */
	(function init() {
	  map = renderMap();
    renderMarkers();
	})();


	function renderMap() {
    const html = `<div class="${map_selector}"></div>`;

    $this.append(html);
    $map = $this.find(`.${map_selector}`);

    const options = {
      zoom: 5,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoomControl: true
    };

    return new google.maps.Map($map[0], options);
  }


  function renderMarkers() {
    const links = $this.find('.sc-links__content a');
    let markers = [];

    $.each(links, (index, el) => {
      const $link = $(el);
      const lat = $link.data('latitude');
      const lng = $link.data('longitude');

      const latLng = new google.maps.LatLng(
        lat,
        lng
      );

      let marker = new google.maps.Marker({
        map: map,
        position: latLng,
        title: $link.text(),
      });
      markers.push(marker);

      bounds.extend(marker.getPosition());

      const contentString = `${$link.text()} <br /> <a href="${$link.attr('href')}">View Airport</a>`;
      const infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      marker.addListener('click', function() {
        infowindow.open(map, marker);
      });
    });

    map.setCenter(bounds.getCenter());

    // Zooms out one level to make sure no markers are at the edge and
    // if there is only one marker, zoom out to include the surrounding area.
    google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
      if (markers.length == 1) {
        this.setZoom(MAX_INITIAL_ZOOM_LEVEL);
      }
    });

    map.fitBounds(bounds);
  }

});


/**
 * JavaScript controller for shortcode: [links.sitemap]
 */
Mmg.addShortcode('links.sitemap', '.sc-links.sitemap', (el, model, $, selector, className) => {
  const $this = $(el);
  const contentSelector = '.sc-links__content';
  const mapWrapperClass = 'sc-links__map-wrapper';
  const breakpoint = $.breakpoint('get');

  /**
   * Load inline map
   */
  (function init(){

    // Don't load the map at x-small and small breakpoints
    if(['xs', 'sm'].includes(breakpoint.size)){
      onComplete();
      return;
    }

    loadMap()
      .done(onLoad)
      .fail(onFail)
      .always(onComplete);
  })();


  /**
   * Load the svg map
   */
  function loadMap(){
    return $.get(model.sitemap_image_url);
  }


  /**
   * Render SVG inline
   * @param {string} data The svg image data
   */
  function onLoad(data){
    const $mapWrapper = $(`<div class="${mapWrapperClass}"></div>`);
    const $content = $this.find(contentSelector);

    if (data.documentElement) {
      $mapWrapper.html(data.documentElement);
      $content.prepend($mapWrapper);
    } else {
      onFail();
    }
  }


  /**
   * Remove the sitemap class/behavior if the svg load fails
   */
  function onFail(){
    $this.removeClass('sitemap')
  }


  /**
   * Update the widget state
   */
  function onComplete(){
    $this.removeClass('loading');
  }

});
