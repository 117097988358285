Mmg["google-map-options"] = () => ({
  styles: [
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
  routeStyles: {
    strokeColor: "#cc0000",
    strokeOpacity: 0.5,
    strokeWeight: 3,
  },
  mapTypeId: google.maps.MapTypeId.ROADMAP,
  draggableCursor: 'pointer',
  scrollwheel: false,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  clickableIcons: false
});

Mmg["google-map-cluster-options"] = () => ({
  gridSize: 20,
  maxZoom: 15,
  styles: [
    {
      url: "https://maps.gstatic.com/mapfiles/transparent.png",
      height: 28,
      width: 28,
      anchorText: [0, 0],
      textColor: "#fff",
      textSize: 12,
      anchorIcon: [36, 12],
    },
  ],
});

// Controls the positioning of the infowindow arrow relative to the marker
// See core map.js for all options
Mmg["google-map-anchor-options"] = () => { anchor: new google.maps.Point(6, 8) };
