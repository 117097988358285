/*
* JavaScript for Header
*/
jQuery(function($){

    const selector = '.footer';
    const target = `${selector}__cta-mobile`;

    (function init(){
        addEvents();
    })();

    function addEvents() {
        Mmg.event.observeSticky(`${selector}__stuck-observer`, target);
    }

});
