/**
 * HTML client side helper
 */
Mmg.html = {

  /**
     * Formats a given number into star-rating representation in html
     *
     * @param {float} rating Number supporting half values. e.g: 4.5
     * @return {string} Returns html mark up
     */
  createHtmlStarRating( rating ){
    template = '<i class="icon icon-star"></i>';

    // using parseFloat to make sure we get a float type
    rating = parseFloat(rating);

    // need to floor rating if half values are used
    html = template.repeat( rating | 0 );

    // Add half star
    if ( rating % 1 >= 0.5 ) {
      html += '<i class="icon icon-star-half"></i>';
    }

    return html;
  }

}
