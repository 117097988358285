/**
 * JavaScript controller for shortcode: anchor_navigation
 */
Mmg.addShortcode('anchor_navigation', '.sc-anchor-navigation', (el, model, $) => {

	const $this = $(el);
	const $nav = $this.find('.sc-anchor-navigation__menu');
	const $anchors = $("[class^='anchor-name-'],[class*=' anchor-name-']");
	const $adminBar = $("#wpadminbar");
	const template = $('.tpl-anchor-navigation-item').html();

	/**
	 * Initialize the widget
	 */
	(function init(){

		render( getAnchors() );
		$this.on('click', 'a', scrollToTarget);

	})();


	/**
	 * Get all anchors on the page
	 * @return array Collection of anchor objects
	 */
	function getAnchors() {

		return $.map( $anchors, function( el ){

			let $el = $(el),
				anchor = getAnchor( $el.attr('class') ),
				id = $el.attr( 'id' )
			;

			// Use existing element id for the anchor target if it's set
			// otherwise, use anchor target for the element id.
			if( id ){
				anchor.target = id;
			}
			else{
				$el.attr('id', anchor.target );
			}

			return anchor;
		});
	}


	/**
	 * Extract an anchor object from the class name
	 * @param  string className The full class attribute matching the anchor naming pattern
	 * @return object           An object containing the anchor name and target
	 */
	function getAnchor( className ){

		// Second character in regex class is a non-breaking hyphen.
		var pattern = /(?:^|[ ])anchor-name-([-‑\p{Letter}\p{Number}\/.&]+)/u,
			matches = className.match(pattern)
		;

		return {
			name: formatAnchorName( matches[1] ),
			target: 'anchor-target-' + matches[1]
		};
	}


	/**
	 * Format the extracted anchor name
	 * @param  string name The unformatted anchor name
	 * @return string 	 The formatted anchor name
	 */
	function formatAnchorName( name ){

		return name.replace(/-/g, ' ');
	}


	/**
	 * Render the anchor navigation
	 *
	 * @param array anchors a collection of anchor objects to be rendered
	 */
	function render( anchors ){

		anchors = anchors.slice(0, model.max);
		$nav.html( Mustache.render(
			template,
			{anchors: anchors}
		));
	}


	/**
	 * Scroll to the targeted element. Account for `stuck` element height
	 * in the scroll position calculation.
	 * 
	 * @param {event} event 
	 */
	function scrollToTarget ( event ){
		const $target = $($(event.target).attr("href"));
		const offsetTop = $target.offset().top;
		const marginTop = parseInt($target.css('marginTop'));
		const adminBarHeight = $adminBar.height() || 0;
		const stuckElementHeight =$('[stuck]').outerHeight() || 0;
		const breathingRoom = 10;
		const bodyTop = Math.max(stuckElementHeight, adminBarHeight);
		const position = offsetTop - marginTop - bodyTop - breathingRoom;

		event.preventDefault();

		window.scrollTo({
			top: position,
			left: 0,
			behavior: model.smooth ? 'smooth' : 'instant',
		});	
	}
});
