/**
 * Main js controller for content_blocks. Collects all content
 * blocks that should be rendered at the client and updates each block.
 *
 * NOTE: This is not a typical shortcode controller as it does
 * not fire for each instance of content_block.
 */
jQuery(function($) {

    const selector = '.sc-content-block';
    const blockSelector = `${selector}__block`;
    const clientSideSelector = `${selector}.render-at-client`;
    const accordionToggleSelector = '.sc-content-block__block-header';
    const maxBytesGet = 8000;

    /**
     * Initialize the widget
     */
    (function init(){
        const blocks = getClientSideBlocks();
        const $accordion = $(`${selector}.accordion`);

        if (blocks.length) {
            load(blocks).then(render);
        }

        $accordion.on(
            'click',
            accordionToggleSelector,
            handleAccordionToggleClick
        );
    })();


    /**
     * Toggle open/close state of accordion blocks
     * @param {event} event
     */
    function handleAccordionToggleClick(event) {
        $(this).parents(blockSelector).toggleClass('open');
    }


    /**
     * Collect all blocks that are to be rendered at the client.
     */
    function getClientSideBlocks() {
        const $clientSideBlocks = $(clientSideSelector);
        const blocks = $clientSideBlocks.map((index, block) => {

            const blockInputs = $(block).data('json')['inputs'];

            Object.keys(blockInputs).forEach(
              key => {
                if (blockInputs[key] === '' || blockInputs[key] === null) {
                  delete blockInputs[key];
                }
              }
            );

            return {
                ...blockInputs,
                _id: $(block).attr('id'),
            };
        });
        return $.makeArray(blocks);
    }


    /**
     * Load content blocks from the API.
     * @param {array} blocks A collection of blocks with index and autop values.
     */
    function load(blocks) {
        const url = getApiUrl();
        const data = {
            post_id: getPostId(),
            blocks,
        };
        const bytes = new Blob([url, $.param(data)]).size + 1;
        const method = bytes > maxBytesGet ? 'POST' : 'GET';

        return $.ajax({
            url,
            data,
            method,
        });
    }


    /**
     * Replace DOM blocks with results from API.
     * @param {object} results
     */
    function render(results) {
        results.blocks.forEach(block => {

            // Only replace the content blocks, because the intro and title are static.
            // Also, we want to keep event handlers and state for rest of the widget.
            $(block.content).find(blockSelector).each(function (i, div) {
                const newHtml = $(this).html();
                $(`#${div.id}`).html(newHtml).trigger('render');
            });
        });
    }


    /**
     * Get the wordpress post ID from the body tag.
     */
    function getPostId() {
        const matches = $('body').attr('class').match(/postid-([0-9]+)/);
        return matches[1] || '';
    }


    /**
     * Get the API url from the DOM data attribute.
     */
    function getApiUrl() {
        const model = $(clientSideSelector).data('json') ?? {};
        return model.api_url || '';
    }


}); // EOF jQuery()
