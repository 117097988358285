/**
 * LPC Locations v1.0
 * Copyright 2022 Site Assemble
 */
jQuery(document).ready(function($){
    var $zipChangeBox           = $(".zip-change-box"),                         // header & footer zip change box
        $zipChangeBoxFooter     = $(".zip-change-box.footer"),                  // footer box
        $zipChangeBoxHeader     = $(".zip-change-box.header"),                  // header box
        $zipChangeBoxInput      = $(".zip-change-box input"),                   // zip input field
        $zipChangeBoxError      = $(".zip-change-box .error"),                  // error element
        $zipChangeBoxClose      = $(".zip-change-box .close"),                  // close button (footer only)
        $dropPositionContainer  = $(".drop-position"),                          // city + chevron container
        $dropPositionCity       = $(".drop-position .location"),                // city text element
        $dropPositionElem       = $(".drop-position .fas"),                     // chevron element
        $changeLinkFooter       = $(".lpc-address.footer [name=zip-change]"),   // [change] link in footer address
        $showHideSpeed          = 250,
        $setTimeoutSpeed        = 250
    ;

    function getCookie(name) {                                      // get cookie by name
        var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
        return v ? v[2] : null;
    }
    function setCookie(name, value, days) {                         // set cookie by name
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }
    function deleteCookie(name) {                                   // delete cookie by name
        setCookie(name, "", -1);
    }

    function isValidUSZip(sZip) {
        return /^\d{5}(-\d{4})?$/.test(sZip);
    }

    function getUserZip() {                                         // get user zip code via maxmind lookup (functions.php \ maxmind_getzip)
        $.ajax({
            method: "POST",
            url: lpc_ajax_var.url,
            data: {
                action: "maxmind_getzip",
                nonce: lpc_ajax_var.nonce
            },
            success: function (data) {
                var userZip = data;
                if(isValidUSZip(userZip)) {                         // validate that returned data is valid zip code
                    setCookie("zip-lookup", userZip, 365);          // set cookie
                    getLPCLocation(userZip);                        // refresh LPC location
                }
            },
            error: function() {
                console.log("getUserZip() error");
            }
        });
    }

    function getLPCLocation(userZip) {                              // update LPC address
        $.ajax({
            url: lpc_ajax_var.locations_data,
            dataType: "json",
            method: "GET",
            async: true,
            data: {},
            success: function (data) {
                var isInServiceArea = 0;
                for (var i = 0; i < data.length; i++){              // iterate through objects
                    if (data[i].serviceareas.includes(userZip)){    // look for the entry with a matching service area zip code
                        isInServiceArea = 1;
                        // update address in both header and footer areas
                        $(".lpc-address .location, .drop-position .location").html(data[i].location);
                        $(".lpc-address .address").html(data[i].address);
                        $(".lpc-address .city").html(data[i].city);
                        $(".lpc-address .state").html(data[i].state);
                        $(".lpc-address .zip").html(data[i].zip);
                        $(".lpc-address .user-zip").html(userZip);
                        $(".lpc-address .phone").html(data[i].phone).prop("href", "tel:" + data[i].phone);
                    } else {
                        // did not find any location for this zip, leave default location info
                    }
                }
                if (isInServiceArea === 0) {                        // if new zip is NOT in service area, handle $zipChangeBox
                    $zipChangeBoxInput.blur();                      // pull cursor otherwise autofill selector may stay visible over error message
                    $zipChangeBoxError.show();
                } else {                                            // if new zip is in service area
                    if($zipChangeBox.is(":visible")){               // and the box is visible
                        $zipChangeBoxError.hide();                  // hide the invalid zip error
                        setCookie("zip-lookup", userZip, 365);      // set new zip cookie
                        $zipChangeBoxInput.val("");                 // empty zip input
                        if($zipChangeBoxHeader.is(":visible")){     // currently unused - if header box is visible, and footer box receives zip change, leave header box open with updated address
                            //$zipChangeBoxHeader.hide("250");
                            //$dropPositionElem.removeClass("opened");
                            //$dropPositionElem.addClass("closed").css("transform", "rotate(0deg)").css("top", "7px");
                        }
                        if($zipChangeBoxFooter.is(":visible")){
                            $zipChangeBoxFooter.hide("250");
                        }
                    }
                }
            },
            error: function (xhr, exception) {
                console.log("getLPCLocation() error");
            }
        });
    }

    function positionModal(speed){

        var timeoutSpeed = "";
        if (speed !== undefined) {
            timeoutSpeed = speed;
        } else {
            timeoutSpeed = $setTimeoutSpeed;                        // default setTimeout speed
        }

        // top location box positioning
        window.setTimeout(function(){

            var addressBoxWidth = Math.round( $zipChangeBoxHeader.outerWidth() ),                                           // full width of address box
                dropPositionLeft = Math.round( $dropPositionElem.position().left ),                                         // position of chevron from left
                shiftHeaderAddressBox = dropPositionLeft - addressBoxWidth + Math.round($dropPositionElem.width() / 2) + 22 // calculated as: (position of chevron icon) - (width of address box) + (half width of chevron icon - to point) + (distance from right of address box to point of arrow)
            ;

            $zipChangeBoxHeader.css("width", addressBoxWidth);                                                              // lock address box width
            $zipChangeBoxHeader.css("left", shiftHeaderAddressBox + "px");                                                  // position address box horizontally

        },timeoutSpeed);

    }

    function triggerModal(){

        if ( $dropPositionElem.hasClass("closed") ) {                                                                       // if chevron currently closed
            $zipChangeBoxHeader.css("top", "auto").css("display", "none").show($showHideSpeed);                             // reposition vertically, hide/show
            $dropPositionElem.removeClass("closed");                                                                        // remove 'closed' class from chevron
            $dropPositionElem.addClass("opened").css("transform", "rotate(180deg)").css("top", "-2px");                      // add 'opened' class, rotate chevron and reposition
            $("#user-zip-header").focus();                                                                                  // focus on input
        } else {                                                                                                            // chevron is currently open
            $zipChangeBoxHeader.hide(250);                                                                                  // hide address box
            window.setTimeout(function(){                                                                                   // reposition address box out of the visible window
                $zipChangeBoxHeader.css("top", "-1000px").css("display", "auto");
            },$setTimeoutSpeed);
            $dropPositionElem.removeClass("opened");                                                                        // remove 'opened' class
            $dropPositionElem.addClass("closed").css("transform", "rotate(0deg)").css("top", "1px");                        // add 'closed' class, rotate chevron and reposition
        }

    }

    // check to see if user already has zip code cookie
    if (getCookie("zip-lookup")) {                                              // cookie found
        var userZip = getCookie("zip-lookup");
        if(isValidUSZip(userZip)) {                                             // valid zip, fill in correct location
            getLPCLocation(userZip);
        } else {                                                                // invalid zip, delete cookie and get user zip code
            deleteCookie("zip-lookup");
            getUserZip();
        }
    } else {                                                                    // no cookie found, get user zip
        getUserZip();
    }

    // user-initiated zip change
    $changeLinkFooter.on("click",function(){                                    // show modal on change click
        $zipChangeBoxFooter.show($showHideSpeed);
        $("#user-zip-footer").focus();
    });
    $zipChangeBoxClose.on("click",function(){                                   // hide modal on x click
        $zipChangeBox.hide("250");
    });

    $(document).mouseup(function(e){                                            // if the target of the click isn't the container nor a descendant of the container, also check that chevron is currently opened and the parent container is not the target (otherwise it'll close then reopen)
        if (                                                                    // if these are all true, assume element is visible and hide it
        !$zipChangeBox.is(e.target) &&
        $zipChangeBox.has(e.target).length === 0 &&
        $dropPositionElem.hasClass("opened") &&
        !$dropPositionContainer.is(e.target) &&
        $dropPositionContainer.has(e.target).length === 0
        ){
            $zipChangeBox.hide("250");
            $dropPositionElem.removeClass("opened");
            $dropPositionElem.addClass("closed").css("transform", "rotate(0deg)").css("top", "1px");
        } else {                                                                // otherwise it's not visible
            if (                                                                // make sure only a click on the city or chevron elements trigger the modal opening
            $dropPositionContainer.is(e.target) ||
            $dropPositionElem.is(e.target) ||
            $dropPositionCity.is(e.target)
            ){
                triggerModal();
            }
        }
    });


    $("#user-zip-header, #user-zip-footer").on("input", function(){             // listen for changes in the zip code field
        if(!$.isNumeric($(this).val())) {                                       // if there are any non-numeric characters, empty the input field
            $(this).val("");
        }
        if ( $(this).val().length > 5 ) {                                       // if input goes over 5 digits, truncate it and update form field
            var correctedZip = $(this).val().substring(0,5);
            $(this).val(correctedZip);
        }
        if ( ( $(this).val().length == 5 ) && $.isNumeric($(this).val()) ) {    // correct value, check that it is numeric and fire up the form modifier
            getLPCLocation($(this).val());                                      // update location
            positionModal(10);                                                  // reposition top modal box
        }
    });

    $dropPositionContainer.on("click",function(){                               // click top city/chevron selector
        //triggerModal();
    })

    positionModal();                                                            // default modal positioning on page load

});
